import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Color } from "../../report/visitConstant/Color";

const EmployeeInfoCard = ({ icon, title, value, onPress, fontSize = null }) => {
  return (
    <div
      onClick={onPress}
      className="d-flex align-items-center p-sm-3 p-2 py-sm-3 py-2 "
      style={{
        borderRadius: "20px",
        border: "1px solid #E5E5E5",
        boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
        backgroundColor: "white",
        minWidth: "140px",
        // height: 100,
        maxWidth: 300,
        flex: 1,
        cursor: "pointer",
        // marginBottom: 20,
      }}
    >
      {/* Icon Section */}
      <div
        className="p-2 p-md-3"
        style={{
          // padding: "15px",
          borderRadius: "12px",
          backgroundColor: Color.lightPrimary,
          marginRight: "15px",
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          color={Color.primary}
          // className="fs-4"
          style={{ fontSize: window?.innerWidth < 768 ? "18px" : "24px" }}
        />
      </div>

      {/* Text Section */}
      <div>
        <p
          style={{
            margin: 0,
            fontSize: window?.innerWidth < 768 ? "14px" : "16px",
            color: Color.grey,
            overflow: "hidden",
            textOverflow: "clip",
            whiteSpace: "normal", // Allows wrapping on whitespace
            wordBreak: "break-word",
          }}
        >
          {title}
        </p>
        <h3
          style={{
            fontSize:
              window?.innerWidth < 768
                ? fontSize
                  ? "20px"
                  : "18"
                : fontSize
                ? "28px"
                : "20px",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          {value}
        </h3>
      </div>
    </div>
  );
};

export default EmployeeInfoCard;
