import {
  faUserCheck,
  faLayerGroup,
  faUserGroup,
  faSmile,
  faAd,
  faAdd,
  faFileExport,
  faDollyBox,
  faMultiply,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { Color } from "../../report/visitConstant/Color";
import EmployeeInfoCard from "./EmployeeInfoCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmployeeList from "./EmployeeList";
import PendingApprovalCard from "./PendingApprovalCard";
import AddNewEmployee, { formatTime } from "./AddNewEmployee";
import { useEffect, useState } from "react";
import moment from "moment";
import apiHelper from "../../report/ApiHelpar/apiHelper";
import {
  GENERATE_REPORT,
  GET_ALL_DEPARTMENT,
  GET_ALL_SHIFT,
  GET_DAILY_ATTENDENCE,
  SEARCH_EMPLOYEE_BY_NAME,
} from "../../report/visitConstant/ApiConstant";
import EditEmployee from "./EditEmployee";
import EmptyEmpView from "./EmptyEmpView";
import useDebounce from "../../report/useDebounce/useDebounce";
import { calRunningAndDiffTime } from "../helper/CalculateTime";

const Attendance = () => {
  const [date, setDate] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: "",
  });
  const [departmentItems, setDepartmentItems] = useState([]);
  const [shiftItems, setShiftItems] = useState([]);
  const [editEmployee, setEditEmployee] = useState(null);
  const [subLoader, setSubLoader] = useState(false);

  const [refLoading, setRefLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [employeeList, setEmployeeList] = useState({
    all: [],
    active: [],
    inactive: [],
    onBoard: [],
  });

  // const [activeList, setActiveList] = useState({
  //   title: "Active Staff",
  //   data: employeeList.all,
  // });
  const [activeList, setActiveList] = useState(() => {
    const savedActiveList = sessionStorage.getItem("activeListA");
    return savedActiveList
      ? JSON.parse(savedActiveList)
      : { title: "Active Staff", data: employeeList?.active };
  });

  // const [isLoading, setIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(() => {
    const savedIsLoading = sessionStorage.getItem("isLoadingA");
    return savedIsLoading ? JSON.parse(savedIsLoading) : true;
  });

  const handleOnChange = (text) => {
    try {
      setSearchKey(text?.trim());
      if (text?.trim() !== "") {
        const filterdData = employeeList?.all?.filter((item) =>
          item?.employee?.name?.toLowerCase()?.includes(text?.toLowerCase())
        );
        if (filterdData?.length > 0) {
          setActiveList({
            title: `Search results`,
            data: filterdData,
          });
        } else {
          setActiveList({
            title: `Search results`,
            data: [],
          });
        }
      } else {
        setActiveList({
          title: "Total Staff",
          data: employeeList?.all,
        });
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const GetEmployeeList = async () => {
    try {
      const data = {
        startDate: date?.startDate,
        endDate: date?.endDate || moment(new Date()).format("YYYY-MM-DD"),
        // employeeId:2,
      };

      // console.log(data, " kdkkd");
      const res = await apiHelper(GET_DAILY_ATTENDENCE, data);

      const resData = res?.respData?.respMsg;
      // console.log(resData, "  responsefor");
      // const no = resData?.find((item) => item.employee?.id == 17);
      // console.log("sksk ", no?.attendance?.length, "ed", no);

      if (Array.isArray(resData)) {
        // setEmployeeList(resData);
        const allResData = {
          all: resData,
          active: resData?.filter(
            (item) =>
              Array.isArray(item.attendance) &&
              item.attendance?.some((a) => a && a?.checkInTime !== null)
          ),
          inactive: resData?.filter(
            (item) =>
              item.attendance?.includes(null) || item.attendance?.length === 0
          ),
          onBoard: [],
        };
        // console.log(r, " allresData");
        setEmployeeList(allResData);
        const defaultList = { title: "Active Staff", data: allResData?.active };

        setActiveList(defaultList);
        sessionStorage.setItem("activeListA", JSON.stringify(defaultList));
      } else {
        setEmployeeList([]);
      }
    } catch (e) {
      console.log("Getting error from ManageEmp ", e);
    } finally {
      setIsLoading(false);
      setRefLoading(false);
      setSubLoader(false);
      sessionStorage.setItem("isLoadingA", JSON.stringify(false));
    }
  };

  const getAllShiftItem = async () => {
    try {
      const shiftRes = await apiHelper(GET_ALL_SHIFT, {});
      const shiftResResponse = shiftRes?.respData?.respMsg;
      // console.log(shiftResResponse, "d");
      if (Array.isArray(shiftResResponse)) {
        setShiftItems(shiftResResponse);
      } else {
        setShiftItems([]);
      }
    } catch (e) {}
  };

  const getAllDepartmentItem = async () => {
    try {
      const res = await apiHelper(GET_ALL_DEPARTMENT, {});

      const departmentRes = res?.respData?.respMsg;
      //   console.log(departmentRes, "f");

      if (Array.isArray(departmentRes)) {
        setDepartmentItems(departmentRes);
      } else {
        setDepartmentItems([]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    GetEmployeeList();
    getAllShiftItem();
    getAllDepartmentItem();
  }, []);

  const jsonToCSV = (json) => {
    const header = [
      "Name",
      "Mobile No",
      "Department",
      "Allocated Shift",
      "Start and End time",
      "Date",
      "Working Shift",
      "Punch-In",
      "Punch-Out",
      "Completed Hours",
      "Overtime Hours",
      "Status",
    ].join(",");
    // const header = Object.keys(json[0]?.employee).join(",");
    const rows = json.map((row) => {
      const { employee, attendance } = row;

      // Map attendance entries into rows
      const attendanceRows = attendance.map((entry) => {
        const workingShift = entry?.shift?.name;
        const punchIn = entry?.checkInTime
          ? formatTime(entry.checkInTime)
          : "_ _ _";
        const punchOut = entry?.checkOutTime
          ? formatTime(entry.checkOutTime)
          : "_ _ _";
        const dates = entry?.date
          ? moment(entry?.date).format("DD-MMM-YYYY")
          : "_ _ _";
        const completedHours = calRunningAndDiffTime(
          entry?.checkInTime,
          entry?.checkOutTime
        );
        const overtime =
          entry?.overtimeHours !== 0 ? `${entry?.overtimeHours} hrs` : "_ _ _";
        const status = entry?.checkInTime ? "Present" : "Absent";

        return [
          employee?.name || "_ _ _",
          employee?.mobileNo || "_ _ _",
          employee?.department?.name || "_ _ _",
          employee?.shift?.name || "_ _ _",
          `${formatTime(employee?.shift?.startTime)} - ${formatTime(
            employee?.shift?.endTime
          )} (${employee?.shift?.totalHours} hrs)`,
          dates,
          workingShift,
          punchIn,
          punchOut,
          completedHours || "_ _ _",
          overtime,
          status,
        ].join(",");
      });

      return attendanceRows.join("\n");
    });

    // Calculate number of columns
    const numColumns = header.split(",").length;

    // Create padding for centering text
    const totalPaddingCells = numColumns - 1;
    const leftPaddingCells = Math.floor(totalPaddingCells / 2);
    const rightPaddingCells = totalPaddingCells - leftPaddingCells;

    const centeredText = `${",".repeat(leftPaddingCells)}"Jivdani Hospital "${
      activeList?.title
    } ( From ${moment(date?.startDate).format("DD-MMM-YYYY")} to ${
      date?.endDate ? moment(date?.endDate).format("DD-MMM-YYYY") : "N/A"
    }) ${",".repeat(rightPaddingCells)}`;

    const csvContent = [centeredText, header, ...rows].join("\n");
    return csvContent;
  };

  const downloadCSV = () => {
    const csvContent = jsonToCSV(activeList?.data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // console.log(" jdjd >", activeList?.data);

  return (
    <div>
      <div
        className=" d-flex bg-white w-100 px-sm-4 py-sm-4 py-3 py-lg-4 px-3 border-bottom justify-content-between align-items-end"
        style={{ minHeight: 80, gap: 20, flexWrap: "wrap" }}
      >
        <p
          className=" d-lg-block d-none "
          style={{
            fontWeight: "bold",
            flex: 0.5,
            fontFamily: "sans-serif",
            margin: 0,
            fontSize: window?.innerWidth < 768 ? "18px" : "24px",
          }}
        >
          Manage Attendance
        </p>

        <div
          className="d-sm-flex align-items-end d-lg-justify-content-lg-center "
          style={{ flex: 1, gap: 20 }}
        >
          <div className="d-flex column-gap-3 row-gap-2 mb-3 mb-sm-0 ">
            <div className="d-flex align-items-center flex-wrap row-gap-1 ">
              <span className="fw-semibold me-2" style={{ color: Color.grey }}>
                Start
              </span>
              <input
                name="startdate"
                type="date"
                className="form-control fw-medium"
                placeholder="dd/mm/yyyy"
                required
                aria-required="true"
                value={date.startDate}
                onChange={(e) =>
                  setDate((prev) => ({ ...prev, startDate: e?.target?.value }))
                }
                style={{
                  outline: "none",
                  boxShadow: "none",
                  // border: "1px solid gray",
                  borderWidth: 1,
                  width: window?.innerWidth > 850 ? 150 : 148,
                  borderRadius: "12px",
                  borderColor: Color.lightGray,
                }}
              />
            </div>
            <div className="d-flex align-items-center flex-wrap row-gap-1">
              <span className="fw-semibold me-2" style={{ color: Color.grey }}>
                End
              </span>
              <input
                name="enddate"
                type="date"
                className="form-control fw-medium"
                placeholder="Select End Date"
                required
                aria-required="true"
                value={date.endDate}
                onChange={(e) =>
                  setDate((prev) => ({ ...prev, endDate: e?.target?.value }))
                }
                max={moment().format("YYYY-MM-DD")}
                style={{
                  outline: "none",
                  boxShadow: "none",
                  borderWidth: 1,
                  width: window?.innerWidth > 850 ? 150 : 148,
                  borderRadius: "12px",
                  borderColor: Color.lightGray,
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary fw-medium align-items-center"
            style={{
              whiteSpace: "nowrap",
              borderWidth: 1,
              width: 76,
              borderRadius: "12px",
              borderColor: Color.primary,
            }}
            onClick={() => {
              setSubLoader(true);
              GetEmployeeList();
            }}
          >
            {subLoader ? (
              <span
                class="spinner-border spinner-border-sm text-white"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <span className="text-center">Submit </span>
            )}
          </button>
        </div>

        <div
          className="d-flex justify-content-md-end "
          style={{
            flex: 0.5,
          }}
        >
          <div
            className="d-flex border px-1 bg-white "
            style={{
              width: window?.innerWidth < 438 ? "90vw" : "250px",
              // minWidth: 250,
              borderRadius: "12px",
              borderColor: Color.lightGray,
            }}
          >
            <input
              type="text"
              className="form-control  border-0 fw-medium"
              placeholder="Search Staff"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              style={{
                outline: "none",
                boxShadow: "none",
                color: "black",
              }}
              value={searchKey}
              onChange={(e) => handleOnChange(e?.target?.value)}
            />
            <button
              className="btn border-0 "
              type="button"
              style={{ color: Color.primary }}
              id="SearchButton"
              onClick={() => {
                if (searchKey?.length > 0) {
                  handleOnChange("");
                  setActiveList({
                    title: "Total Staff",
                    data: employeeList?.all,
                  });
                }
              }}
            >
              {searchKey?.length > 0 ? (
                <FontAwesomeIcon icon={faMultiply} />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        className="px-sm-4 px-3"
        style={{ maxHeight: "90vh", overflowY: "scroll", paddingBottom: 40 }}
      >
        <div
          className="d-flex justify-content-between my-sm-4 my-3 "
          style={{
            columnGap: "20px",
            // minHeight: 224,

            minHeight: 100,
          }}
        >
          <div
            className="d-flex "
            style={{
              gap: "20px",
              flex: 1.5,
              flexWrap: "wrap",
            }}
          >
            <EmployeeInfoCard
              icon={faUserGroup}
              title="Total Staff"
              value={employeeList?.all?.length}
              onPress={() => {
                handleOnChange("");
                setActiveList({
                  title: "Total Staff",
                  data: employeeList?.all,
                });
              }}
            />
            <EmployeeInfoCard
              icon={faUserCheck}
              title="Active Staff"
              value={employeeList?.active?.length}
              onPress={() => {
                handleOnChange("");
                setActiveList({
                  title: "Active Staff",
                  data: employeeList?.active,
                });
              }}
            />

            <EmployeeInfoCard
              icon={faSmile}
              title="On Leave"
              value={employeeList?.inactive?.length}
              onPress={() => {
                handleOnChange("");
                setActiveList({
                  title: "On Leave",
                  data: employeeList?.inactive,
                });
              }}
            />
            <EmployeeInfoCard
              icon={faLayerGroup}
              title="Onboarding"
              value={employeeList?.onBoard?.length}
              onPress={() => {
                handleOnChange("");
                setActiveList({
                  title: "Onboarding",
                  data: employeeList?.onBoard,
                });
              }}
            />
          </div>
          {/* <div className="" style={{ flex: 1, height: "100%" }}>
            <PendingApprovalCard />
          </div> */}
        </div>

        <div
          className="bg-white p-3"
          style={{
            borderRadius: "20px",
            border: "1px solid #E5E5E5",
            boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
            marginBottom: "20px",
          }}
        >
          <div className="d-flex justify-content-between g-2 my-2  align-items-center py-1">
            <p
              className="fw-semibold text-center"
              style={{
                fontFamily: "revert-layer",
                color: "black",
                margin: 0,
              }}
            >
              {activeList?.title} ({activeList?.data?.length})
            </p>
            <div className="d-flex" style={{ gap: 20 }}>
              <button
                type="button"
                className="d-flex align-items-center btn text-black  fw-medium"
                style={{
                  whiteSpace: "nowrap",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E5E5E5",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  borderColor: Color.lightGray,
                  fontSize: 14,
                  height: 36,
                }}
                onClick={downloadCSV}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  color={"black"}
                  className="me-sm-2"
                  style={{ fontSize: 14 }}
                />
                <span className="d-none d-sm-block">Export To CSV</span>
              </button>
              <button
                type="button"
                className="d-flex align-items-center btn text-white  fw-medium"
                style={{
                  whiteSpace: "nowrap",
                  borderRadius: "12px",
                  backgroundColor: "#f15757",
                  border: "1px solid #E5E5E5",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  borderColor: Color.lightGray,
                  fontSize: 14,
                  height: 36,
                }}
                onClick={() => {
                  setRefLoading(true);
                  GetEmployeeList();
                }}
              >
                <FontAwesomeIcon
                  icon={faRefresh}
                  color={"white"}
                  className={`me-sm-2 ${refLoading && "fa-spin"}`}
                  style={{ fontSize: 14 }}
                />
                <span className="d-none d-sm-block">Refresh</span>
              </button>
              {/* <AddNewEmployee /> */}
            </div>
          </div>

          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 150 }}
            >
              <span
                class="spinner-border spinner-border-sm text-primary mb-5"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          ) : activeList.data?.length > 0 ? (
            <div
              style={{
                overflowY: "scroll",
                maxHeight: "90vh",
              }}
            >
              <EmployeeList
                employeList={activeList.data}
                onEdit={(employeeItem) => setEditEmployee(employeeItem)}
                date={date}
              />
            </div>
          ) : (
            <div className="mb-5">
              <EmptyEmpView
                title={`There is no '${activeList.title}'`}
                icon={faDollyBox}
              />
            </div>
          )}
        </div>
      </div>
      {editEmployee && (
        <EditEmployee
          fetchRootData={GetEmployeeList}
          shiftItems={shiftItems}
          departmentItems={departmentItems}
          editObjEmpl={editEmployee}
          isAttendanceCall={true}
          onClose={() => setEditEmployee(null)}
          activeDate={date?.startDate}
        />
      )}
    </div>
  );
};

export default Attendance;
