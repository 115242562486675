import Select from "react-select";
import { formatTime } from "./AddNewEmployee";
import { Color } from "../../report/visitConstant/Color";

const ShiftSelector = ({
  slectedValue,
  handleInputChange,
  shiftItems,
  inputName,
  isDisabled = false,
}) => {
  const options = shiftItems.map((shift) => ({
    value: shift.id,
    label: shift.name,
    startTime: formatTime(shift.startTime), // Add extra data
    endTime: formatTime(shift.endTime),
    totalHr: shift.totalHours,
  }));

  //    {shift.name} ({formatTime(shift.startTime)} -{" "}
  //                           {formatTime(shift.endTime)}, {shift.totalHours} hrs)

  return (
    <div className="col-8 mt-2">
      <label htmlFor={inputName} className="col-form-label">
        Shift
      </label>

      <Select
        options={options}
        value={options.find((option) => option.value === slectedValue)}
        onFocus={() =>
          handleInputChange({ target: { name: inputName, value: "" } })
        }
        isClearable={true}
        isDisabled={isDisabled}
        onChange={(selectedOption) =>
          handleInputChange({
            target: {
              name: inputName,
              value: selectedOption ? selectedOption.value : "",
            },
          })
        }
        className="basic-select"
        classNamePrefix="select"
        placeholder="Select Shift"
        getOptionLabel={(e) => (
          <div>
            {/* <strong style={{ fontSize: 14 }}>{e.label}</strong>{" "} */}
            {/* Shift Name */}
            <div style={{ fontSize: "small" }}>
              <span className=" fw-normal" style={{ fontSize: 14 }}>
                {e.label}
              </span>{" "}
              ( {e.startTime} - {e.endTime}, {e.totalHr} hrs )
              {/* Start Time and End Time */}
            </div>
          </div>
        )}
        styles={{
          control: (provided, state) => ({
            ...provided,
            backgroundColor: isDisabled ? "#e9ecef" : "white", // Light grey when disabled

            cursor: isDisabled ? "not-allowed" : "default", // Pointer indication
            // borderColor: state.isFocused ? Color.lightPrimary : "#ced4da",

            boxShadow: state.isFocused
              ? "0 0 0 0.2rem rgba(130, 80, 255, 0.2)"
              : null,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? Color.primary // Selected option background
              : state.isFocused
              ? Color.lightPrimary // Focused option background
              : "white",
            color: state.isSelected ? "white" : "black", // Text color
            cursor: "pointer",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "black", // Disabled text color
          }),
          menu: (provided) => ({
            ...provided,
            maxHeight: "180px", // Set the maximum height for the menu
            overflow: "hidden", // Ensures the scroll works within the defined height
          }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: "180px", // Height of the scrollable container
            overflowY: "auto", // Enables vertical scrolling
          }),
        }}
      />
    </div>
  );
};

export default ShiftSelector;
