import moment from "moment";

export const calRunningAndDiffTime = (punchIn, punchOut = null) => {
  const inTime = moment(punchIn, "HH:mm");
  let outTime;

  if (!punchOut) {
    // If punchOut is not provided, use the current time
    outTime = moment();
  } else {
    // If punchOut is provided, parse it
    outTime = moment(punchOut, "HH:mm");
  }

  // If outTime is before inTime, assume it crosses midnight
  if (outTime.isBefore(inTime)) {
    outTime.add(1, "day");
  }

  // Calculate the duration
  const duration = moment.duration(outTime.diff(inTime));
  const diffHours = Math.floor(duration.asHours());
  const diffMinutes = duration.minutes();

  // return `${diffHours} hrs ${diffMinutes} min`;
  return punchOut
    ? `${diffHours > 0 ? ` ${diffHours} hrs` : ""} ${
        diffMinutes > 0 ? `${diffMinutes} mins` : ""
      } `
    : `${
        diffHours > 0 ? ` ${diffHours} hrs` : ""
      } ${diffMinutes} mins (Running)`;
};
