
import React, { useEffect, useState } from "react";
import AddContent from "./AddContent";

const Advertisement = ({
  position = "center", // Options: top-left, top-center, top-right, middle-left, center, middle-right, bottom-left, bottom-center, bottom-right
  size = "medium", // Options: small, medium, large, extra-large
  content = null, // Content to display (text, image, video, etc.)
  onClose = () => {}, // Callback for closing the advertisement
}) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  // Map positions to CSS classes
  const positionClasses = {
    "top-left": "position-fixed top-0 start-0",
    "top-center": "position-fixed top-0 start-50 translate-middle-x",
    "top-right": "position-fixed top-0 end-0",
    "middle-left": "position-fixed top-50 start-0 translate-middle-y",
    center: "position-fixed top-50 start-50 translate-middle",
    "middle-right": "position-fixed top-50 end-0 translate-middle-y",
    "bottom-left": "position-fixed bottom-0 start-0",
    "bottom-center": "position-fixed bottom-0 start-50 translate-middle-x",
    "bottom-right": "position-fixed bottom-0 end-0",
  };

  // Card size styles
  const sizeStyles = {
    small: { width: "30%", height: "auto" },
    medium: { width: "50%", height: "auto" },
    large: { width: "70%", height: "auto" },
    "extra-large": { width: "90%", height: "auto" },
  };


// if (!isVisible){
// setTimeout(() => {
//   setIsVisible(true);
// }, 200);
// }
  


  // console.log("AddContent", content);

  return (
    <div
      className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center"
      style={{
        zIndex: 1050,
       // background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
        top: 0,
        left: 0,
      }}
    >
      <div
        className={`shadow-lg overflow-hidden ${positionClasses[position]}`}
        style={{
          ...sizeStyles[size],
          maxHeight: "90%", // Prevent overflow
          overflow: "hidden",
          backgroundColor: "#fff", // Card background color
          borderRadius: "0.75rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          position: "relative",
        }}
      >
        {/* Close Button */}
       

        <AddContent
          content={content}
          setIsVisible={setIsVisible}
          onClose={onClose}
        />
      </div>
    </div>
  );
};

export default Advertisement;

