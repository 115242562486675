import React from "react";
import { Color } from "../report/visitConstant/Color";
import moment from "moment";
import { formatTime } from "./component/AddNewEmployee";

const stylebg = {
  backgroundColor: Color.background,
  paddingTop: 18,
  paddingBottom: 18,
  fontSize: 14,
  color: Color.grey,
  whiteSpace: "nowrap",
};

const calculateWorkHours = (punchIn, punchOut = null) => {
  const inTime = moment(punchIn, "HH:mm");
  let outTime;

  if (!punchOut) {
    // If punchOut is not provided, use the current time
    outTime = moment();
  } else {
    // If punchOut is provided, parse it
    outTime = moment(punchOut, "HH:mm");
  }

  // If outTime is before inTime, assume it crosses midnight
  if (outTime.isBefore(inTime)) {
    outTime.add(1, "day");
  }

  // Calculate the duration
  const duration = moment.duration(outTime.diff(inTime));
  const diffHours = Math.floor(duration.asHours());
  const diffMinutes = duration.minutes();

  // return `${diffHours} hrs ${diffMinutes} min`;
  return punchOut
    ? `${diffHours > 0 ? ` ${diffHours} hrs` : ""} ${
        diffMinutes > 0 ? `${diffMinutes} mins` : ""
      } `
    : `${
        diffHours > 0 ? ` ${diffHours} hrs` : ""
      } ${diffMinutes} mins (Running)`;
};

const EmpReportList = ({ employeList, date }) => {
  // console.log(employeList, "d");

  const data = employeList[0];
  const printedShifts = new Set(); // Use Set to track unique shifts
  console.log(printedShifts);
  const renderAttendanceShifts = (entry) => {
    const uniqueKey = `${entry?.date}-${entry?.shift?.name?.trim()}`;
    if (!printedShifts.has(uniqueKey)) {
      printedShifts.add(uniqueKey); // Mark shift as printed
      return (
        <div>
          <span
            className="me-2"
            style={{
              flexWrap: "nowrap",
              whiteSpace: "nowrap",
            }}
          >
            {entry?.shift?.name?.trim()}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span
            className="me-2"
            style={{
              flexWrap: "nowrap",
              whiteSpace: "nowrap",
            }}
          >
            _ _ _
          </span>
        </div>
      );
    }
  };

  const renderTableBody = (attendance) => {
    const printedDates = new Set(); // Track unique dates
    let idx = 0;
    return attendance?.map((item, index) => {
      const isDatePrinted = !printedDates.has(item?.date);
      if (isDatePrinted) {
        printedDates.add(item?.date);
        idx++;
      } // Mark the date as printed

      return (
        <tr
          key={item?.id}
          style={{
            fontSize: 14,
          }}
          className="fw-medium"
        >
          <th scope="row">{isDatePrinted ? idx : ""}</th>

          <td>
            {isDatePrinted && (
              <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
                {item?.date ? moment(item?.date).format("DD-MMM-YY") : "_ _ _"}
              </div>
            )}
          </td>

          <td>
            <div className="fw-medium">{renderAttendanceShifts(item)}</div>
          </td>
          <td>
            <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
              {item?.checkInTime ? formatTime(item?.checkInTime) : "_ _ _"}
            </div>
          </td>
          <td>
            <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
              {item?.checkOutTime ? formatTime(item?.checkOutTime) : "_ _ _"}
            </div>
          </td>

          <td>
            <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
              {item?.checkOutTime
                ? calculateWorkHours(item?.checkInTime, item?.checkOutTime)
                : "_ _ _"}
            </div>
          </td>
          <td>
            <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
              {item?.overtimeHours} hrs
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div className="mt-3 table-responsive">
      <table className="table table-md table-hover  table-responsive ">
        {/* Table Header */}
        <thead>
          <tr style={{ border: 0, borderColor: "transparent" }}>
            <th
              scope="col"
              style={{
                ...stylebg,
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
              }}
            ></th>

            {date?.endDate && (
              <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
                Date
              </th>
            )}
            <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
              Working Shift
            </th>
            <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
              Punch-In
            </th>
            <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
              Punch-Out
            </th>

            <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
              Completed Hrs
            </th>
            <th
              scope="col"
              className="fw-semibold"
              style={{
                ...stylebg,
                borderTopRightRadius: 12,
                borderBottomRightRadius: 12,
              }}
            >
              {" "}
              OT hours
            </th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody style={{ verticalAlign: "middle" }}>
          {data?.attendance?.length > 0 &&
            !data?.attendance?.includes(null) &&
            renderTableBody(data?.attendance)}
        </tbody>
      </table>
    </div>
  );
};

export default EmpReportList;
