import React, { useEffect, useState } from "react";
import { DATA_SAVED, ERROR_MSG } from "../../Constant";
import ToastFist from "../../report/Toast/ToastFist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import apiHelper from "../../report/ApiHelpar/apiHelper";
import {
  ADD_EMPLOYEE,
  GET_ALL_DEPARTMENT,
  GET_ALL_SHIFT,
} from "../../report/visitConstant/ApiConstant";
import moment from "moment";
import { Modal } from "bootstrap";

export const formatTime = (time) => {
  return moment(time, "HH:mm").format("h:mm A");
};

const AddNewEmployee = ({ fetchRootData }) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    dob: "",
    mobileNumber: "",
    jobTittle: "",
    department: "",
    joiningDate: moment().format("YYYY-MM-DD"),
    leavingDate: null,
    netSalary: "",
    grossSalary: "",
    branchId: "",
    shift: "",
    address: "",
  });

  const [loading, setLoading] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [colorStatus, setColorStatus] = useState(3);
  const [showToast, setShowToast] = useState(false);
  const [isrequired, setIsrequired] = useState(false);
  const [departmentItems, setDepartmentItems] = useState([]);
  const [shiftItems, setShiftItems] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    const modalElement = document.getElementById("AddEmployee");
    const modal = Modal.getInstance(modalElement);

    // modal.hide(); // This will close the modal

    if (modal) {
      modal.hide();
      modalElement.classList.remove("show"); // Force removal of the 'show' class
      document.body.classList.remove("modal-open"); // Remove the 'modal-open' class from body
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove(); // Remove the backdrop manually
      }
    }
  };

  const getAllDropDown = async () => {
    try {
      const res = await apiHelper(GET_ALL_DEPARTMENT, {});
      const shiftRes = await apiHelper(GET_ALL_SHIFT, {});
      const departmentRes = res?.respData?.respMsg;
      // console.log(departmentRes, "f");

      if (Array.isArray(departmentRes)) {
        setDepartmentItems(departmentRes);
      } else {
        setDepartmentItems([]);
      }

      const shiftResResponse = shiftRes?.respData?.respMsg;
      // console.log(shiftResResponse, "d");

      if (Array.isArray(shiftResResponse)) {
        setShiftItems(shiftResResponse);
      } else {
        setShiftItems([]);
      }
    } catch (e) {}
  };

  const handleInputChange = (e) => {
    setIsrequired(false);
    const { name, value } = e.target;
    if (name === "mobileNumber" && value.length > 10) return;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setInputValue((prev) => ({
      ...prev,
      [name]: selectedOption,
    }));
  };

  useEffect(() => {
    getAllDropDown();
  }, []);

  // console.log("inp ", inputValue);

  const resetValue = () => {
    setIsrequired(false);
    setInputValue({
      name: "",
      dob: "",
      mobileNumber: "",
      jobTitle: "",
      department: "",
      joiningDate: moment().format("YYYY-MM-DD"),
      leavingDate: null,
      netSalary: "",
      grossSalary: "",
      branchId: "",
      shift: "",
      address: "",
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(inputValue, "entered data ");
    setLoading(false);

    if (
      !inputValue.name ||
      !inputValue.mobileNumber ||
      !inputValue.jobTittle ||
      !inputValue.dob ||
      !inputValue.department ||
      !inputValue.netSalary ||
      !inputValue.grossSalary ||
      !inputValue.shift
    ) {
      setIsrequired(true);
      setLoading(false);
      setTimeout(() => setIsrequired(false), 3000);
      return;
    }

    try {
      const data = {
        name: inputValue.name,
        mobileNo: inputValue?.mobileNumber,
        dob: inputValue.dob,
        address: null,
        designation: inputValue.jobTittle,
        joiningDate: inputValue?.joiningDate,
        leavingDate: null,
        netSalary: parseFloat(inputValue.netSalary),
        grossSalary: parseFloat(inputValue.grossSalary),
        // branchId: parseInt(inputValue.branchId),
        department: {
          id: parseInt(inputValue.department),
        },
        shift: {
          id: parseInt(inputValue.shift),
        },
      };

      // console.log(data, "passed data ");

      const response = await apiHelper(ADD_EMPLOYEE, data);

      // console.log(response, "response data");
      if (response?.respData?.message !== "Data is not saved") {
        setShowToast(true);
        setLoading(false);
        setToastMsg(DATA_SAVED);
        setColorStatus(1);
        resetValue();
        if (fetchRootData) {
          fetchRootData();
        }
        closeModal();
      } else {
        setShowToast(true);
        setLoading(false);
        setToastMsg(ERROR_MSG);
        setColorStatus(0);
      }
    } catch (e) {
      // console.log("skjjs", e);
      setShowToast(true);
      setLoading(false);
      setToastMsg(ERROR_MSG);
      setColorStatus(0);
    }
  };

  return (
    <>
      <div data-bs-toggle="modal" data-bs-target="#AddEmployee">
        <button
          type="button"
          className="btn text-white fw-medium"
          style={{
            whiteSpace: "nowrap",
            borderRadius: "12px",
            backgroundColor: "#f15757",
            fontSize: 14,
          }}
        >
          <FontAwesomeIcon
            icon={faAdd}
            color={"white"}
            className="me-2"
            style={{ fontSize: 14 }}
          />
          Add Employee
        </button>
      </div>

      <div
        className="modal fade"
        id="AddEmployee"
        tabIndex="-1"
        aria-labelledby="AddEmployeelLabel"
        aria-hidden="true"
        // onClick={resetValue}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 bg-white">
              <h5 className="modal-title" id="AddEmployeelLabel">
                Add Employee
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetValue}
              ></button>
            </div>
            <div className="modal-body bg-white">
              <form>
                <div class="row row-cols-3">
                  <div className="col mt-2">
                    <label htmlFor="name" className="col-form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter name"
                      value={inputValue.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div class="col mt-2">
                    <div className="">
                      <label htmlFor="mobileNumber" className="col-form-label">
                        Mobile Number
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="mobileNumber"
                        name="mobileNumber"
                        placeholder="Enter 10 digit number"
                        maxLength={10}
                        value={inputValue.mobileNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col mt-2">
                    <div className="">
                      <label htmlFor="dob" className="col-form-label">
                        DOB
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="dob"
                        name="dob"
                        placeholder="DD-MM-YYYY"
                        value={inputValue.dob}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* 
                  <div class="col-8 mt-2">
                    <label htmlFor="address" className="col-form-label">
                      Address
                    </label>
                    <textarea
                      className="form-control"
                      id="address"
                      name="address"
                      placeholder="Enter address"
                      rows={1}
                      value={inputValue.address}
                      onChange={handleInputChange}
                      //   style={{ height: "100px" }}
                    ></textarea>
                  </div> */}
                  <div className="mt-2">
                    <label htmlFor="jobTittle" className="col-form-label">
                      Job Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="jobTittle"
                      name="jobTittle"
                      placeholder="Enter job tittle"
                      value={inputValue.jobTittle}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-8 mt-2">
                    <label htmlFor="shift" className="col-form-label">
                      Shift
                    </label>
                    <select
                      className="form-control"
                      name="shift"
                      value={inputValue.shift}
                      onChange={(e) =>
                        handleSelectChange("shift", e.target.value)
                      }
                    >
                      <option value="">Select Shift</option>
                      {shiftItems.map((shift) => (
                        <option key={shift.id} value={shift.id}>
                          {shift.name} ({formatTime(shift.startTime)} -{" "}
                          {formatTime(shift.endTime)}, {shift.totalHour} hrs)
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div class="">
                  <div class="row row-cols-3">
                    <div className="col mt-2">
                      <label htmlFor="department" className="col-form-label">
                        Department
                      </label>
                      <select
                        className="form-control"
                        name="department"
                        value={inputValue.department}
                        onChange={(e) =>
                          handleSelectChange("department", e.target.value)
                        }
                      >
                        <option value="">Select Department</option>
                        {departmentItems.map((dept) => (
                          <option key={dept.id} value={dept.id}>
                            {dept.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="col mt-2">
                      <div className="">
                        <label htmlFor="netSalary" className="col-form-label">
                          Total Salary
                        </label>

                        <input
                          type="number"
                          className="form-control"
                          id="netSalary"
                          name="netSalary"
                          placeholder="Enter total salary"
                          value={inputValue.netSalary}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div class="col mt-2">
                      <div className="">
                        <label htmlFor="grossSalary" className="col-form-label">
                          Gorss Salary
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="grossSalary"
                          name="grossSalary"
                          placeholder="Enter gross salary"
                          value={inputValue.grossSalary}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div class="col mt-2">
                      <div className="">
                        <label htmlFor="joiningDate" className="col-form-label">
                          Joining Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="joiningDate"
                          name="joiningDate"
                          placeholder="DD/MM/YYYY"
                          value={inputValue.joiningDate}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <p style={{ margin: 0, color: "red", marginTop: 12, height: 12 }}>
                {isrequired ? "Need to fill all data" : ""}
              </p>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-outline-primary fw-medium"
                data-bs-dismiss="modal"
                style={{
                  borderRadius: "12px",
                  width: 100,
                }}
                onClick={resetValue}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary fw-medium"
                // data-bs-dismiss="modal"
                onClick={async () => handleSubmit()}
                disabled={loading}
                style={{
                  borderRadius: "12px",
                  width: 100,
                }}
              >
                {loading ? (
                  <span
                    class="spinner-border spinner-border-sm text-white"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};

export default AddNewEmployee;
