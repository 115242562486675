import {
  faUserCheck,
  faLayerGroup,
  faUserGroup,
  faSmile,
  faAd,
  faAdd,
  faFileExport,
  faBeerMugEmpty,
  faBoxOpen,
  faBoxTissue,
  faDollyBox,
  faCross,
  faMultiply,
} from "@fortawesome/free-solid-svg-icons";
import { Color } from "../../report/visitConstant/Color";
import EmployeeInfoCard from "./EmployeeInfoCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmployeeList from "./EmployeeList";
import PendingApprovalCard from "./PendingApprovalCard";
import AddNewEmployee, { formatTime } from "./AddNewEmployee";
import { useEffect, useState } from "react";
import moment from "moment";
import apiHelper from "../../report/ApiHelpar/apiHelper";
import {
  GENERATE_REPORT,
  GET_ALL_DEPARTMENT,
  GET_ALL_EMPLOYEE,
  GET_ALL_SHIFT,
  GET_DAILY_ATTENDENCE,
  SEARCH_EMPLOYEE_BY_NAME,
} from "../../report/visitConstant/ApiConstant";
import ManageEmplyeeList from "./ManageEmplyeeList";
import { all } from "axios";
import EmptyEmpView from "./EmptyEmpView";
import EditEmployee from "./EditEmployee";
import useDebounce from "../../report/useDebounce/useDebounce";
import { calRunningAndDiffTime } from "../helper/CalculateTime";

const ManageEmployee = () => {
  const [date, setDate] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: "",
  });
  const [departmentItems, setDepartmentItems] = useState([]);
  const [shiftItems, setShiftItems] = useState([]);
  const [editEmployee, setEditEmployee] = useState(null);
  const [isDeactive, setIsDeactive] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [employeeList, setEmployeeList] = useState({
    all: [],
    active: [],
    inactive: [],
    onBoard: [],
  });

  const [activeList, setActiveList] = useState(() => {
    const savedActiveList = sessionStorage.getItem("activeList");
    return savedActiveList
      ? JSON.parse(savedActiveList)
      : { title: "Total Staff", data: employeeList?.all };
  });

  const [isLoading, setIsLoading] = useState(() => {
    const savedIsLoading = sessionStorage.getItem("isLoading");
    return savedIsLoading ? JSON.parse(savedIsLoading) : true;
  });

  const handleOnChange = (text) => {
    setSearchKey(text);
    if (text?.trim() !== "") {
      const filterdData = employeeList?.all?.filter((item) =>
        item?.name?.toLowerCase()?.includes(text?.toLowerCase())
      );

      setActiveList({
        title: `Search results`,
        data: filterdData.length > 0 ? filterdData : [],
      });
    } else {
      setActiveList({
        title: "Total Staff",
        data: employeeList?.all,
      });
    }
  };

  const GetEmployeeList = async () => {
    try {
      const data = {};

      // console.log(data, " kdkkd");
      const res = await apiHelper(GET_ALL_EMPLOYEE, data);

      const resData = res?.respData?.respMsg;
      // console.log(resData, "  responsefor");

      if (Array.isArray(resData)) {
        const allResData = {
          all: resData,
          active: resData?.filter((item) => item.activeStatus),
          inactive: resData?.filter((item) => !item.activeStatus),
          onBoard: [],
        };
        setEmployeeList(allResData);
        const defaultList = { title: "Total Staff", data: resData };

        setActiveList(defaultList);
        sessionStorage.setItem("activeList", JSON.stringify(defaultList));
      } else {
        setEmployeeList([]);
      }
    } catch (e) {
      setIsLoading(false);
      // console.log("Getting error from ManageEmp ", e);
    } finally {
      setIsLoading(false);
      sessionStorage.setItem("isLoading", JSON.stringify(false));
    }
  };

  const getAllShiftItem = async () => {
    try {
      const shiftRes = await apiHelper(GET_ALL_SHIFT, {});
      const shiftResResponse = shiftRes?.respData?.respMsg;
      // console.log(shiftResResponse, "d");
      if (Array.isArray(shiftResResponse)) {
        setShiftItems(shiftResResponse);
      } else {
        setShiftItems([]);
      }
    } catch (e) {}
  };

  const getAllDepartmentItem = async () => {
    try {
      const res = await apiHelper(GET_ALL_DEPARTMENT, {});

      const departmentRes = res?.respData?.respMsg;
      //   console.log(departmentRes, "f");

      if (Array.isArray(departmentRes)) {
        setDepartmentItems(departmentRes);
      } else {
        setDepartmentItems([]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    GetEmployeeList();
    getAllShiftItem();
    getAllDepartmentItem();
  }, []);

  const jsonToCSV = (json) => {
    const header = [
      "Name",
      "Mobile No",
      "Department",
      "Joining Date",
      "Allocated Shift",
      "Start and End time",
      "Leaving Date",
      "Status",
    ].join(",");

    const rows = json.map((row) => {
      const employeeRow = [
        row?.name || "_ _ _",
        row?.mobileNo || "_ _ _",
        row?.department?.name || "_ _ _",
        row?.joiningDate
          ? moment(row?.joiningDate).format("DD-MM-YYYY")
          : "_ _ _",
        row?.shift?.name || "_ _ _",
        `${formatTime(row?.shift?.startTime || "")} - ${formatTime(
          row?.shift?.endTime || ""
        )} (${row?.shift?.totalHours || "0"} hrs)`,
        row?.leavingDate
          ? moment(row?.leavingDate).format("DD-MM-YYYY")
          : "_ _ _",
        row?.activeStatus ? "Present" : "Left",
      ];

      return employeeRow.join(",");
    });

    // Calculate number of columns
    const numColumns = header.split(",").length;

    // Create padding for centering text
    const totalPaddingCells = numColumns - 1;
    const leftPaddingCells = Math.floor(totalPaddingCells / 2);
    const rightPaddingCells = totalPaddingCells - leftPaddingCells;

    const centeredText = `${",".repeat(leftPaddingCells)}"Jivdani Hospital "${
      activeList?.title
    }${",".repeat(rightPaddingCells)}`;

    const csvContent = [centeredText, header, ...rows].join("\n");
    return csvContent;
  };

  const downloadCSV = () => {
    const csvContent = jsonToCSV(activeList?.data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // console.log(activeList?.data, "d");
  return (
    <div>
      <div
        className=" d-sm-flex bg-white w-100 px-sm-4 py-sm-4 py-3 py-lg-4 px-3  border-bottom justify-content-between "
        style={{ minHeight: 80, gap: 20 }}
      >
        <p
          className="text-start d-none d-lg-block"
          style={{
            flex: 0.5,
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: window?.innerWidth < 768 ? "18px" : "24px",
            margin: 0,
          }}
        >
          Manage Staff
        </p>

        <div
          className="d-flex  justify-content-center "
          // style={{
          //   flex: 1,
          // }}
        >
          <div
            className="d-flex border px-1 bg-white "
            style={{
              width: window?.innerWidth < 768 ? "100%" : "280px",
              borderRadius: "12px",
              borderColor: Color.lightGray,
            }}
          >
            <input
              type="text"
              className="form-control  border-0 fw-medium"
              placeholder="Search Staff"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              style={{
                outline: "none",
                boxShadow: "none",
                color: "black",
              }}
              value={searchKey}
              onChange={(e) => handleOnChange(e?.target?.value)}
            />
            <button
              className="btn border-0 "
              type="button"
              style={{ color: Color.primary }}
              id="SearchButton"
              onClick={() => {
                if (searchKey?.length > 0) {
                  handleOnChange("");
                  setActiveList({
                    title: "Total Staff",
                    data: employeeList?.all,
                  });
                }
              }}
            >
              {searchKey?.length > 0 ? (
                <FontAwesomeIcon icon={faMultiply} />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        className="px-sm-4 px-3"
        style={{ maxHeight: "90vh", overflowY: "scroll", paddingBottom: 40 }}
      >
        <div
          className="d-flex justify-content-between my-sm-4 my-3"
          style={{
            columnGap: "20px",
            minHeight: 100,
          }}
        >
          <div
            className="d-flex row-gap-sm-4 row-gap-3 column-gap-sm-4 column-gap-3"
            style={{
              flex: 1.5,
              flexWrap: "wrap",
            }}
          >
            <EmployeeInfoCard
              icon={faUserGroup}
              title="Total Staff"
              value={employeeList?.all?.length}
              onPress={() => {
                handleOnChange("");
                setActiveList({
                  title: "Total Staff",
                  data: employeeList?.all,
                });
              }}
            />
            <EmployeeInfoCard
              icon={faUserCheck}
              title="Active Staff"
              value={employeeList?.active?.length}
              onPress={() => {
                handleOnChange("");
                setActiveList({
                  title: "Active Staff",
                  data: employeeList?.active,
                });
              }}
            />

            <EmployeeInfoCard
              icon={faSmile}
              title="Inactive Staff"
              value={employeeList?.inactive?.length}
              onPress={() => {
                handleOnChange("");
                setActiveList({
                  title: "Inactive Staff",
                  data: employeeList?.inactive,
                });
              }}
            />
            <EmployeeInfoCard
              icon={faLayerGroup}
              title="Onboarding"
              value={employeeList?.onBoard?.length}
              onPress={() => {
                handleOnChange("");
                setActiveList({
                  title: "Onboarding",
                  data: employeeList?.onBoard,
                });
              }}
            />
          </div>
          {/* <div className="" style={{ flex: 1, height: "100%" }}>
            <PendingApprovalCard />
          </div> */}
        </div>

        <div
          className="bg-white p-3"
          style={{
            borderRadius: "20px",
            border: "1px solid #E5E5E5",
            boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
            marginBottom: "20px",
            // overflowY: "scroll",
          }}
        >
          <div className="d-flex justify-content-between g-2 my-2  align-items-center pb-1">
            <p
              className="fw-semibold text-center"
              style={{
                fontFamily: "revert-layer",
                color: "black",
                margin: 0,
              }}
            >
              {activeList.title} ({activeList?.data?.length})
            </p>
            <div className="d-flex" style={{ gap: 20 }}>
              <button
                type="button"
                className="d-flex btn  align-items-center text-black  fw-medium"
                style={{
                  whiteSpace: "nowrap",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E5E5E5",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  borderColor: Color.lightGray,
                  fontSize: 14,
                  height: 36,
                }}
                onClick={downloadCSV}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  color={"black"}
                  className="me-sm-2"
                  style={{ fontSize: 14 }}
                />
                <span className="d-none d-sm-block">Export To CSV</span>
              </button>

              {/* <AddNewEmployee fetchRootData={GetEmployeeList} /> */}

              <button
                type="button"
                className="d-flex align-items-center btn text-white fw-medium"
                style={{
                  whiteSpace: "nowrap",
                  borderRadius: "12px",
                  backgroundColor: "#f15757",
                  fontSize: 14,
                  height: 36,
                }}
                onClick={() => {
                  setIsDeactive(false);
                  setEditEmployee(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  color={"white"}
                  className="me-sm-2 "
                  style={{ fontSize: 14 }}
                />
                <span className="d-none d-sm-block">Add New Staff</span>
                {/* <span className="d-block d-sm-none">Add</span> */}
              </button>
            </div>
          </div>

          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 150 }}
            >
              <span
                class="spinner-border spinner-border-sm text-primary mb-5"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          ) : activeList.data?.length > 0 ? (
            <div
              style={{
                overflowY: "scroll",
                maxHeight: "90vh",
              }}
            >
              <ManageEmplyeeList
                employeList={activeList.data}
                onEdit={(employeeItem, isTrue) => {
                  setEditEmployee(employeeItem);
                  setIsDeactive(isTrue);
                }}
              />
            </div>
          ) : (
            <div className="mb-5">
              <EmptyEmpView
                title={`There is no '${activeList.title}'`}
                icon={faDollyBox}
              />
            </div>
          )}
        </div>
      </div>
      {editEmployee && (
        <EditEmployee
          fetchRootData={GetEmployeeList}
          shiftItems={shiftItems}
          departmentItems={departmentItems}
          editObjEmpl={editEmployee}
          isAttendanceCall={false}
          onClose={() => {
            setEditEmployee(null);
            setIsDeactive(false);
          }}
          isDeletePress={isDeactive}
        />
      )}
    </div>
  );
};

export default ManageEmployee;
