import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Color } from "../../report/visitConstant/Color";
import { color } from "framer-motion";
import moment from "moment";
import { formatTime } from "./AddNewEmployee";
import { EditButton } from "./ManageEmplyeeList";
import { useNavigate } from "react-router-dom";

const stylebg = {
  backgroundColor: Color.background,
  paddingTop: 18,
  paddingBottom: 18,
  fontSize: 14,
  color: Color.grey,
  whiteSpace: "nowrap",
};

const GenerateEmpList = ({ employeList, onView }) => {
  const sortedEmployeeList = [...employeList].sort((a, b) =>
    a?.employee?.name.localeCompare(b?.employee?.name)
  );

  return (
    <div className="mt-3 table-responsive">
      <table className="table table-md table-hover  table-responsive ">
        {/* Table Header */}
        <thead>
          <tr style={{ border: 0, borderColor: "transparent" }}>
            <th
              scope="col"
              style={{
                ...stylebg,
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
              }}
            ></th>
            {/* {date?.endDate && (
              <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
                Date
              </th>
            )} */}
            <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 170 }}
            >
              Name
            </th>
            <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 170 }}
            >
              Mobile Number
            </th>

            {/* <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 130 }}
            >
              Mobile Number
            </th> */}
            {/* <th scope="col" className="fw-semibold" style={stylebg}>
              Job Title
            </th> */}
            <th scope="col" className="fw-semibold" style={stylebg}>
              Department
            </th>

            <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 200 }}
            >
              {/* Shift */}
              {/* Total Work Hrs */}
              Allocated Shift
            </th>
            {/* <th scope="col" className="fw-semibold" style={stylebg}>
              Status
            </th> */}
            <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
              Total Working Days
            </th>
            <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 90 }}
            >
              Total OT Hours
            </th>

            <th
              scope="col"
              className="fw-semibold"
              style={{
                ...stylebg,
                borderTopRightRadius: 12,
                borderBottomRightRadius: 12,
              }}
            >
              {/* Action */}
            </th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody style={{ verticalAlign: "middle" }}>
          {sortedEmployeeList.map((item, index) => (
            <tr
              key={item?.employee.id}
              style={{
                fontSize: 14,
              }}
              className="fw-medium"
            >
              <th scope="row">{index + 1}</th>
              {/* {date?.endDate && (
                <td>
                  <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
                    {item?.attendance?.length > 0 &&
                    !item?.attendance?.includes(null) ? (
                      item?.attendance.map((entry, idx) => (
                        <div key={idx}>
                          {entry?.date
                            ? moment(entry?.date).format("DD-MM-YY")
                            : "_ _ _"}
                        </div>
                      ))
                    ) : (
                      <div className="fw-medium">_ _ _</div>
                    )}
                  </div>
                </td>
              )} */}
              <td>
                <div className="d-flex align-items-center ">
                  {/* {item?.employee?.imageUrl && (
                    <img
                      src={item?.employee?.imageUrl}
                      alt={item?.employee?.name}
                      className="rounded-circle me-2"
                      style={{ width: "40px", height: "40px" }}
                    />
                  )} */}
                  {item?.employee?.name}
                </div>
              </td>

              <td>
                {item?.employee?.mobileNo ? item?.employee?.mobileNo : "_ _ _"}
              </td>

              <td>
                {item?.employee?.department?.name
                  ? item?.employee?.department?.name
                  : "_ _ _"}
              </td>
              {/* <td>
                {moment(item?.employee?.joiningDate).format("DD-MM-YYYY")}
              </td> */}
              <td style={{ whiteSpace: "nowrap" }}>
                {item?.employee?.shift?.name
                  ? item?.employee?.shift?.name
                  : "_ _ _"}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>{item?.totalWorkingDays}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                {item?.totalOvertimeHours}
              </td>

              <td>
                {/* <EditButton onEdit={() => onEdit(item)} /> */}
                <button
                  className="btn border btn-outline-primary "
                  onClick={() => onView(item)}
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GenerateEmpList;
