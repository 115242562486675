import { useState, useEffect } from "react";

function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return isOnline;
}

export default useNetworkStatus;


/// Example how to use


// import React from "react";
// import useNetworkStatus from "./useNetworkStatus";

// function NetworkStatusIndicator() {
//   const isOnline = useNetworkStatus();

//   return (
//     <div>
//       <h1>Network Status</h1>
//       <p>
//         You are currently <strong>{isOnline ? "Online" : "Offline"}</strong>.
//       </p>
//       {!isOnline && <p style={{ color: "red" }}>Please check your connection!</p>}
//     </div>
//   );
// }

// export default NetworkStatusIndicator;
