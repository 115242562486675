import React, { useEffect, useState } from "react";
import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  ERROR_MSG,
  GETALLMASTERVISITPADSBUTD,
  NO_HISTORY_FOUND,
  update_User_Token,
} from "../Constant";

import { useDispatch } from "react-redux";
import { updateField } from "./ReduxApi/ReduxApi";
import axios from "axios";
import apiHelper from "./ApiHelpar/apiHelper";
import useDebounce from "./useDebounce/useDebounce";
import UserSearchDropdown from "./CustomDropdown/UserSearchDropdown";
import { useNavigate } from "react-router-dom";
import TokenModal from "../booking/TokenModal";
import moment from "moment";
import ToastFist from "./Toast/ToastFist";
import {
  GET_DATA_BYNAME_MOBILE,
  getDataByNameOrMobileNo,
} from "./visitConstant/ApiConstant";

import { iconColor, headerText } from "./visitConstant/repotOtherConstant";
import { setLastHistory } from "./ReduxApi/VisitReducerCount";
import useNetworkStatus from "./Network/useNetworkStatus";

const ReportHeader = ({ receivedDatas }) => {
  // const [receivedData, setReceiveData] = useState(receivedDatas);
  const [receivedData, setReceiveData] = useState(receivedDatas);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // console.log("dd", receivedData)
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const [editToken, setEditToken] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [colorStatus, setColorStatus] = useState(3);
    const [toastMsg, setToastMsg] = useState("");


   const isOnline = useNetworkStatus();

  useEffect(() => {
if (!isOnline){
  setToastMsg(" Offline , Please check your connection");
  setColorStatus(0);
  setShowToast(true);

}
  // Update the state whenever the receivedDatas prop changes
  setReceiveData(receivedDatas);

    // console.log(receivedDatas ,"receivedDatas====>")
  }, [receivedDatas]); // Dependency array includes receivedDatas



  const onSelect = async (value) => {
    // console.log(value, "value----->>>");

    // const data = {
    //   customerId: value?.customerId?.customerId,
    // };

    // const response = await apiHelper(GETALLMASTERVISITPADSBUTD, data);

    // const resData = response == false ? false : response.respData.respMsg;

    // dispatch(setLastHistory(resData?.length > 0 ? resData[0] : []));
    navigate("/reportMain", { state: value });

    setInputValue("");
    setOptions([]);
  };

  useEffect(() => {
    // Ensure receivedData and nested fields exist before dispatching
    dispatch(updateField("customerName", receivedData?.customerId?.name));
    dispatch(updateField("date", receivedData?.date));
    dispatch(
      updateField("phoneNo", Number(receivedData?.customerId?.mobileNo))
    );
    dispatch(updateField("tokenId", Number(receivedData?.tokenId)));
    dispatch(updateField("customerId", receivedData?.customerId?.customerId));

    dispatch(updateField("userId", Number(localStorage.getItem(CUSTOMER_ID))));
  }, [receivedDatas, receivedData, dispatch]);

  // Use the debounced input outside the event handler

  const debouncedInput = useDebounce(inputValue, 200);
  // Effect to handle debounced input change
  useEffect(() => {
    if (debouncedInput?.length > 1) {
      if (!isNaN(debouncedInput) && debouncedInput.trim() !== "") {
        if (debouncedInput?.length < 6) {
          fetchData({ tokenId: debouncedInput });
        } else {
          fetchData({ mobileNo: debouncedInput });
        }
      } else {
        fetchData({ name: debouncedInput }); // Call fetchData with name
      }
    }
  }, [debouncedInput]); // Trigger this effect whenever the debounced input changes

  let ageGenderInfo = "";

  if (receivedData) {
    const age = receivedData?.customerId?.age
      ? `${receivedData?.customerId?.age} ${
          receivedData?.customerId?.ageUnit || "Years"
        }`
      : "";

    // console.log(receivedData, "age-");
    const gender = receivedData.customerId?.gender
      ? receivedData?.customerId?.gender == "M"
        ? "Male"
        : receivedData?.customerId?.gender == "F"
        ? "Female"
        : "Other"
      : "";

    // Create the parentheses content based on availability
    if (age && gender) {
      ageGenderInfo = `(${age}, ${gender})`;
    } else if (age) {
      ageGenderInfo = `(${age})`;
    } else if (gender) {
      ageGenderInfo = `(${gender})`;
    }
  }

  const fetchData = async (SerchingValue) => {
    try {
      // const endpoint = GET_DATA_BYNAME_MOBILE;
      const endpoint = "getDataByNameOrMobileNo";
      const data = SerchingValue;
      const response = await apiHelper(endpoint, data);
      //   console.log("******", response);
      if (response.respData.respMsg == "done") {
        setOptions([]);
      } else {
        setOptions(response.respData.respMsg);

        //  console.log(    "****",response.respData)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchValueChange = (value) => {
    setInputValue(value.toUpperCase());
    if (value?.length == 0) {
      setOptions([]);
    }
  };

  const handleUpdateToken = async (tokenData) => {
    // console.log(tokenData, "Save tg");
    try {
      const busiParams = {
        ...tokenData,
      };

      const response = await apiHelper(update_User_Token, busiParams);
      // console.log(response, " sss repotheader");
      if (
        response.respData.message == "UserToken has been updated successfully"
      ) {
        fetchTokens(tokenData);
      } else {
        setToastMsg("Not updated");
        setColorStatus(0);
        setShowToast(true);
      }
    } catch (error) {
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };

  const fetchTokens = async (tokenData) => {
    try {
      const busiParams = {
        tokenId: tokenData.tokenId,
      };

      const response = await apiHelper("getTokenByTokenNumber", busiParams);
      // console.log(response.respData.respMsg, "getTokenByTokenNumber");
      if (response.respData.respMsg) {
        setReceiveData(response?.respData.respMsg);
        setToastMsg("Token updated successfully");
        setColorStatus(1);
        setShowToast(true);
      } else {
        setColorStatus(0);
        setToastMsg("Not updated");
        setShowToast(true);
      }
    } catch (error) {
      // swal("Error", error.message, "error");
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };

  // navigate("/reportMain", { state: data });

  return (
    <>
      {editToken && (
        <header>
          <TokenModal
            token={receivedData}
            onUpdate={handleUpdateToken}
            onClose={() => setEditToken(null)}
          />
        </header>
      )}
      <nav class="navbar bg-white ">
        <div class="container-fluid">
          {/* <a class="navbar-brand">Navbar</a> */}
          <div onClick={() => setEditToken(true)} style={{ cursor: "pointer" }}>
            <span className="fw-bold" style={{ color: headerText }}>
              {receivedData?.customerId?.salutation &&
                receivedData?.customerId?.salutation}{" "}
              {receivedData?.customerId?.name} {ageGenderInfo}
            </span>
            {/* <br /> */}
            <span style={{ color: "#8250ff" }}>
              {" "}
              {receivedData?.tokenId}
            </span> | {receivedData?.customerId?.mobileNo}
          </div>
          <form class="d-flex" role="search">
            {/* <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={inputValue}
             
              onChange={handleInputChange}
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button> */}

            <UserSearchDropdown
              options={options}
              handleInputChange={handleSearchValueChange}
              onSelect={onSelect}
              value={inputValue}
              rowIndex={1}
              loading={loading}
              searchTerm={inputValue}
            />
          </form>
        </div>
      </nav>

      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};
export default ReportHeader;
