import Select from "react-select";
import { Color } from "../../report/visitConstant/Color";

const DepartmentSelector = ({
  slectedValue,
  handleInputChange,
  departmentItems,
  inputName,
  isDisabled = false,
}) => {
  const options = departmentItems.map((dept) => ({
    value: dept.id,
    label: dept.name,
  }));

  return (
    <div className="col mt-2">
      <label htmlFor={inputName} className="col-form-label">
        Department
      </label>

      <Select
        options={options}
        value={
          slectedValue
            ? options.find((option) => option.value === slectedValue)
            : null
        }
        onFocus={() =>
          handleInputChange({ target: { name: inputName, value: "" } })
        }
        isClearable={true}
        isDisabled={isDisabled}
        onChange={(selectedOption) =>
          handleInputChange({
            target: {
              name: inputName,
              value: selectedOption
                ? { id: selectedOption.value, name: selectedOption?.label }
                : "",
            },
          })
        }
        className="basic-select"
        classNamePrefix="select"
        placeholder="Select Department"
        styles={{
          control: (provided, state) => ({
            ...provided,
            backgroundColor: isDisabled ? "#e9ecef" : "white", // Light grey when disabled

            cursor: isDisabled ? "not-allowed" : "default", // Pointer indication
            boxShadow: state.isFocused
              ? "0 0 0 0.2rem rgba(130, 80, 255, 0.2)"
              : null,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "black", // Disabled text color
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? Color.primary // Selected option background
              : state.isFocused
              ? Color.lightPrimary // Focused option background
              : "white",
            color: state.isSelected ? "white" : "black", // Text color
            cursor: "pointer",
          }),
          menu: (provided) => ({
            ...provided,
            maxHeight: "110px", // Set the maximum height for the menu
            overflow: "hidden", // Ensures the scroll works within the defined height
          }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: "110px", // Height of the scrollable container
            overflowY: "auto", // Enables vertical scrolling
          }),
        }}
      />
    </div>
  );
};

export default DepartmentSelector;
