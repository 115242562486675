import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import CustomTooltip from "../../CustomTooltip";
import {
  ConvertObj,
  convertOnlyValue,
  DataConverte,
  DataConvertecheck,
  filterNewOptions,
  filterNewOptionsD,
  isValidData,
} from "../../DataConverte/DataConverte";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../ReduxApi/ReduxApi";
import IconFunctionality from "../../IconFunctionality";
import Categories from "../../visitConstant/Categories";
import ToastFist from "../../Toast/ToastFist";
import apiHelper from "../../ApiHelpar/apiHelper";
import { DROPDOWNSARCHYNAME } from "../../visitConstant/ApiConstant";
import useDebounce from "../../useDebounce/useDebounce";

import {
  customSelectStyles,
  DropdownIndicator,
  IndicatorSeparator,
  SinglecustomSelectStyles,
} from "../../ui/CSS/customStyles";

import CreatableSelect from "react-select/creatable";
import { handleNewOptions } from "../../Utils/handleNewOptions";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import { addItemToServer } from "../../Utils/addItemToServer";
import DurationDropdown from "../../CustomDropdown/durationDropdown";
import getApiHelper from "../../ApiHelpar/getApiHelper";
import { ERROR_MSG } from "../../../Constant";

const Diagnosis = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);

  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const [TempletDataReserve, setTempletDataReserve] = useState([]);
  const defaultOptions = visitPadData?.diagnosis || [];
  const [options, setOptions] = useState(defaultOptions);
  const [searchKey, setSearchKey] = useState("");
  const [searchTerms, setSearchTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [focusedRowIndex, setFocusedRowIndex] = useState(null);
  const debouncedInput = useDebounce(searchKey, 100);

  const [rows, setRows] = useState([
    { id: "", diagnosis: "", duration: "", date: "" },
  ]);

  const serverConvertData = (data) => {
    return data.map((item) => ({
      id: item.id,
      diagnosis: {
        id: item.diagnosis.value,
        name: item.diagnosis.label,
      }, // Extract the value from diagnosis
      duration: item.duration,
      date: item.date,
    }));
  };

  const handleSelectChange = async (
    index,
    field,
    selectedOption,
    SelectKey
  ) => {
    // const updatedOptions = await handleNewOptions(selectedOption, SelectKey);
    // console.log(selectedOption, " calling")
    if (field === "diagnosis") {
      // Check if the diagnosis already exists
      const isDiagnosisAlreadySelected = rows.some(
        (row, idx) => row[field]?.value === selectedOption?.id && idx !== index
      );

      if (isDiagnosisAlreadySelected) {
        setShowToast(true);
        setToastMsg("Item has already been selected.");
        setColorStatus(3);
        setSearchKey("");
        return;
      }
    }
    const newRows = [...rows];
    if (field === "diagnosis") {
      newRows[index].id = selectedOption && selectedOption?.id;
      newRows[index][field] = selectedOption && {
        value: selectedOption.id,
        label: selectedOption.name,
      };
    } else if (field === "duration") {
      newRows[index][field] = selectedOption && selectedOption;
    }

    //
    setRows(newRows);
    setFocusedRowIndex(null);
    setOptions(defaultOptions);

    const DataFilter = newRows.filter((item) => item?.diagnosis);

    const NewData = serverConvertData(DataFilter);
    dispatch(updateField("diagnosisData", NewData));

   // console.log("diagnosisData", NewData)
    const newSearchTerms = [...searchTerms];
    newSearchTerms[index] = "";
    if (index === rows.length - 1) {
      newSearchTerms.push("");
    }
    // Update the search terms state
    setSearchTerms(newSearchTerms);
    setSearchKey("");
    // console.log("diagnosisData===>", NewData);

    if (index === rows?.length - 1) {
      addRow();
    }
  };








  const handleDateChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].date = value;
    setRows(newRows);

    const DataFilter = newRows.filter((item) => item?.diagnosis);
    const NewData = serverConvertData(DataFilter);
    dispatch(updateField("diagnosisData", NewData));
    // console.log("diagnosisData====>", NewData);

    if (index === rows?.length - 1) {
      addRow();
    }
  };

  const handleInputChange = (value, index) => {
    const newSearchTerms = [...searchTerms];
    newSearchTerms[index] = value;
    setSearchTerms(newSearchTerms);
    setSearchKey((newSearchTerms[index] = value));
  };

  const addRow = () => {
  //  console.log("calling 2");
    setRows([...rows, { id: "", diagnosis: "", duration: "", date: "" }]);
  };

  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    const newSearchTerms = [...searchTerms];
    newSearchTerms.splice(index, 1);
    setRows(newRows);
    const NewData = serverConvertData(newRows);
    dispatch(updateField("diagnosisData", NewData));
    setSearchKey("");
    setSearchTerms(newSearchTerms);
  };

  // Access the data from Redux state
  const clearAllData = useSelector((state) => state?.ClearAllData?.isLoggedIn);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    setRows([{ id: "", diagnosis: "", duration: "", date: "" }]);
    //  addRow()
    setTempletDataReserve([]);
    dispatch(updateField("diagnosisData"));
    setSearchKey("");
    setSearchTerms([]);
  }, [dispatch]);

  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);


 useEffect(() => {
   if (isValidData(patientData?.diagnosisData)) {
     setTempletDataReserve(patientData.diagnosisData);
     // console.log("Valid diagnosisData:", patientData.diagnosisData);
   } else {
     handleClearData();
     //  console.log(
     //    "Invalid or missing diagnosisData:",
     //    patientData?.diagnosisData
     //  );
   }
 }, [patientData, dispatch]);

  useEffect(() => {
    if (TempletDataReserve?.length > 0) {
      const serveData = TempletDataReserve.map((item) => ({
        id: item?.diagnosis?.id,
        diagnosis: {
          value: item?.diagnosis?.id,
          label: item?.diagnosis?.name,
        },
        duration: item?.duration,
        date: item?.date,
      }));

      // console.log(serveData, 'serveData');

      // Use a map to track existing IDs
      const updatedRowsMap = new Map();

      // Populate the map with serveData
      serveData.forEach((newItem) => {
        updatedRowsMap.set(newItem.id, newItem);
      });

      // Filter existing rows and update or add them
      const filteredRows = rows.reduce((acc, row) => {
        if (row.diagnosis?.value && updatedRowsMap.has(row.diagnosis.value)) {
          // Update existing row
          acc.push(updatedRowsMap.get(row.diagnosis.value));
          updatedRowsMap.delete(row.diagnosis.value); // Remove it from the map after processing
        }
        return acc;
      }, []);

      // Add any new items that were not already in existing rows
      updatedRowsMap.forEach((newItem) => {
        filteredRows.push(newItem);
      });

      // Add an empty row at the end
      filteredRows.push({
        id: "",
        diagnosis: "",
        duration: "",
        date: "",
      });

      setRows(filteredRows);

      const DataFilter = filteredRows.filter((item) => item?.diagnosis);
      const NewData = serverConvertData(DataFilter);

      dispatch(updateField("diagnosisData", NewData));
      // console.log("useEffect data serveData", NewData);
    }
  }, [TempletDataReserve]);

 

  const TempletDataTransfer = (TempletData) => {
    // console.log(TempletData, " 1");
    // console.log(rows, " 2");
    if (TempletData?.diagnosisData?.length == 0) {
      setColorStatus(0);
      setToastMsg("Data not available");
      setShowToast(true);
    }
    const safeTempletData = Array.isArray(TempletData.diagnosisData)
      ? TempletData.diagnosisData
      : [];

    // Now spread it safely
    // console.log([...safeTempletData, ...rows], " crc");

    // Set the state after ensuring both are arrays
    setTempletDataReserve([...safeTempletData]);
  };

  useEffect(() => {
    // const newOptions = filterNewOptionsD(rows, defaultOptions)
    // if (debouncedInput && debouncedInput.length >= 1) {
    //   const inputExistsInDefault = defaultOptions.some((option) => {
    //     return option.name
    //       ?.toString()
    //       .toLowerCase()
    //       .startsWith(debouncedInput.toString().toLowerCase());
    //   });

    //   if (!inputExistsInDefault) {
    //     const CategoriesID = Categories?.DIAGNOSIS?.catID;
    //     setIsLoading(true);
    //     fetchData(CategoriesID, debouncedInput);
    //   } else {
    //     setOptions(defaultOptions);
    //   }
    // } else {
    //   setOptions(defaultOptions);
    // }

       if (debouncedInput && debouncedInput.length >= 1) {
     const CategoriesID = Categories?.DIAGNOSIS?.catID;
     setIsLoading(true);
     fetchData(CategoriesID, debouncedInput);
       }

  }, [debouncedInput]);

  const fetchData = async (CategoriesID, debouncedInput) => {
    // console.log("APIN CALINF")
    if (debouncedInput) {
      try {
        const endpoint = DROPDOWNSARCHYNAME;
        const data = {
          catId: CategoriesID,
          prefix: debouncedInput,
        };
        const response = await getApiHelper(endpoint, data);

        // console.log(debouncedInput, "response", response);
        // const newOptions = filterNewOptionsD(rows, response?.respData?.respMsg)
        const newOptions = response?.respData?.respMsg;
        if (
          response?.respData?.respMsg ===
          "No specific work defined for category: INVESTIGATIONS"
        ) {
          setOptions([]);
        } else if (newOptions?.length > 0) {
          setOptions(newOptions);
        } else {
          setOptions([]);
        }

        setIsLoading(false);
      } catch (error) {
        // console.log("Error fetching data:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false); // Hide loader
      }
    }
  };

  const handleAddNewItem = async (option, categoryKey, index) => {
    // console.log("calli Additem", option, categoryKey);
    const newData = await addItemToServer(option, categoryKey);
    const formattedData = {
      id: newData?.value,
      name: newData?.label,
    };

    if (newData == false) {
      setSearchTerms("")
      setShowToast(true);
      setToastMsg(ERROR_MSG);
      setColorStatus(0);
    } else {
      handleSelectChange(
        index,
        "diagnosis",
        formattedData,
        Categories?.DIAGNOSIS?.catID
      );
    }
  };

  // console.log(rows, " fff")

  return (
    <>
      <IconFunctionality
        name="Diagnosis"
        iconSave
        iconRefresh
        iconErase
        iconPlus
        onClick={handleClearData}
        catID={Categories?.DIAGNOSIS?.catID}
        fieldName={Categories?.DIAGNOSIS?.name}
        setTempData={TempletDataTransfer}
      />
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
      <div className="col-12  col-xxl-9 mx-4 pe-4">
        <table className="table table-sm table-bordered">
          <thead className="table-primary" style={{ fontSize: 13 }}>
            <tr>
              <th
                scope="col"
                style={{
                  width: 30,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                #
              </th>
              <th scope="col" style={{ width: 400, paddingLeft: 14 }}>
                Diagnosis
              </th>
              <th scope="col" style={{ width: 200, paddingLeft: 14 }}>
                Duration
              </th>
              <th scope="col" style={{ width: 200, paddingLeft: 14 }}>
                Date
              </th>
              <th scope="col" style={{ width: 32 }}></th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, index) => (
              <tr
                style={{
                  alignContent: "center",
                  fontSize: 13,
                  verticalAlign: "middle",
                }}
                key={`${row.id}-${index}`}
                // key={row?.id}
              >
                <th
                  scope="row"
                  style={{ alignContent: "center", textAlign: "center" }}
                >
                  {index + 1}
                </th>
                <td style={{ alignContent: "center" }}>
                  <CustomDropdown
                    key={index}
                    options={options}
                    handleInputChange={(value, rowIndex) =>
                      handleInputChange(value, rowIndex)
                    }
                    onFocus={() => setFocusedRowIndex(index)}
                    onBlur={() => setFocusedRowIndex(null)}
                    onSelect={(selectedOption, rowIndex) =>
                      handleSelectChange(
                        rowIndex,
                        "diagnosis",
                        selectedOption,
                        Categories?.DIAGNOSIS?.catID
                      )
                    }
                    value={
                      focusedRowIndex === index
                        ? searchTerms[index]?.toUpperCase() || ""
                        : row?.diagnosis.label ||
                          searchTerms[index]?.toUpperCase()
                    }
                    rowIndex={index}
                    isLoading={isLoading}
                    searchTerm={
                      focusedRowIndex === index // Track specific search term for each row
                        ? searchTerms[index]?.toUpperCase() || ""
                        : ""
                    }
                    onAddNewItem={(item) =>
                      handleAddNewItem(
                        item,
                        Categories?.DIAGNOSIS?.catID,
                        index
                      )
                    }
                  />
                </td>
                <td>
                  {row?.diagnosis.label && (
                    <DurationDropdown
                      value={row?.duration}
                      onChange={(selectedOption) =>
                        handleSelectChange(index, "duration", selectedOption)
                      }
                    />
                  )}
                </td>
                <td>
                  {row?.diagnosis.label && (
                    <input
                      type="date"
                      aria-label="Date"
                      className="form-control"
                      style={{ border: 0, borderRadius: 0 }}
                      // value={row?.date}
                      value={row?.date || ""}
                      name="date"
                      onChange={(e) =>
                        handleDateChange(index, e?.target?.value)
                      }
                    />
                  )}
                </td>
                <td
                  className="text-center"
                  onClick={() => index !== rows.length - 1 && removeRow(index)}
                >
                  {index !== rows.length - 1 && (
                    <CustomTooltip
                      icon="fa-solid fa-trash-can"
                      tooltipText="Delete"
                      onClick={() => removeRow(index)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Diagnosis;
