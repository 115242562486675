import React, { useCallback, useEffect, useState } from "react";
import IconFunctionality from "../../IconFunctionality";
import { updateField } from "../../ReduxApi/ReduxApi";
import { useDispatch, useSelector } from "react-redux";
import Categories from "../../visitConstant/Categories";
import { isValidData } from "../../DataConverte/DataConverte";

const GeneralExaminations = ({ patientData }) => {
  const dispatch = useDispatch();
    const handleClearData = useCallback(() => {
      setGeneralExaminations("");
      dispatch(updateField("generalExaminations", ""));
    }, [dispatch]);

  const [TempletDataReserve, setTempletDataReserve] = useState(
    patientData?.generalExaminations
  );
  useEffect(() => {
    if (isValidData(patientData?.generalExaminations)) {
      setTempletDataReserve(patientData?.generalExaminations);
    } else {
      handleClearData();
    }
  }, [patientData]);
  const [generalExaminations, setGeneralExaminations] = useState("");
  const handleGeneralExaminations = (e, name) => {
    setGeneralExaminations(e.target.value);

    dispatch(updateField(name, e.target.value));
  };

  // Access the data from Redux state
  const clearAllData = useSelector((state) => state?.ClearAllData?.isLoggedIn);

  // Handle clearing all data


  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  useEffect(() => {
     if (isValidData(patientData?.generalExaminations)) {
    if (TempletDataReserve) {
      setGeneralExaminations(TempletDataReserve);

      dispatch(updateField("generalExaminations", TempletDataReserve));
    }
  }else{
    handleClearData()
  }
  }, [patientData, TempletDataReserve]);

  const TempletDataTransfer = (TempletData) => {
    setGeneralExaminations(
      `${generalExaminations} - ${TempletData?.generalExaminations}`
    );

    setTempletDataReserve(
      `${generalExaminations} - ${TempletData?.generalExaminations}`
    );
  };

  return (
    <>
      <IconFunctionality
        catID={Categories?.GENERAL_EXAMINATIONS?.catID}
        fieldName={Categories?.GENERAL_EXAMINATIONS?.name}
        name="General Examinations"
        iconSave
        iconRefresh
        iconErase
        onClick={() => handleClearData()}
        setTempData={TempletDataTransfer}
      />

      <div className="col-12 col-xxl-9 mx-4 pe-4 ">
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          name="generalExaminations"
          value={generalExaminations}
          placeholder="General Examinations..."
          onChange={(e) => handleGeneralExaminations(e, "generalExaminations")}
          maxLength={500}
        ></textarea>
      </div>
    </>
  );
};
export default GeneralExaminations;
