import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Color } from "../../report/visitConstant/Color";

const EmptyEmpView = ({ icon, title }) => {
  return (
    <div className="d-flex align-items-center justify-content-center align-content-center py-4">
      <div
        style={{
          padding: "15px",
          borderRadius: "12px",
          backgroundColor: Color.lightPrimary,
          marginRight: "15px",
          // width: 50,
          height: 52,
        }}
      >
        <FontAwesomeIcon icon={icon} color={Color.primary} className="fs-4" />
      </div>

      {/* Text Section */}
      <div>
        <p
          style={{
            margin: 0,
            fontSize: "16px",
            color: Color.grey,
          }}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default EmptyEmpView;
