import React, { useEffect, useState } from "react";
import EmployeeMenu from "./component/EmployeeMenu";
import { Color } from "../report/visitConstant/Color";
import ManageEmployee from "./component/ManageEmployee";
import Attendance from "./component/Attendance";

import ShiftDepartment from "./component/ShiftDepartment";
import GenerateReport from "./component/GenerateReport";

const Employee = () => {
  const [activeIdx, setActiveIdx] = useState(() => {
    const savedActiveIdx = sessionStorage.getItem("savedActiveIdx");
    return savedActiveIdx ? JSON.parse(savedActiveIdx) : 0;
  });
  const handleSideBar = (clickedCatId) => {
    setActiveIdx(clickedCatId);
  };

  return (
    <div style={{ paddingTop: 100 }} className="min-vh-100 ">
      <div className="d-lg-flex">
        <div className="bg-white ">
          <EmployeeMenu handleSideBar={handleSideBar} />
        </div>
        <div style={{ width: "100%", backgroundColor: Color.background }}>
          {activeIdx === 0 ? (
            <ManageEmployee />
          ) : activeIdx === 1 ? (
            <Attendance />
          ) : activeIdx === 2 ? (
            <GenerateReport />
          ) : (
            <ShiftDepartment />
          )}
        </div>
      </div>
    </div>
  );
};

export default Employee;
