import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Color } from "../../report/visitConstant/Color";
import { color } from "framer-motion";
import moment from "moment";
import { formatTime } from "./AddNewEmployee";
import { EditButton } from "./ManageEmplyeeList";

const stylebg = {
  backgroundColor: Color.background,
  paddingTop: 18,
  paddingBottom: 18,
  fontSize: 14,
  color: Color.grey,
  whiteSpace: "nowrap",
};

const calculateWorkHours = (punchIn, punchOut = null) => {
  const inTime = moment(punchIn, "HH:mm");
  let outTime;

  if (!punchOut) {
    // If punchOut is not provided, use the current time
    outTime = moment();
  } else {
    // If punchOut is provided, parse it
    outTime = moment(punchOut, "HH:mm");
  }

  // If outTime is before inTime, assume it crosses midnight
  if (outTime.isBefore(inTime)) {
    outTime.add(1, "day");
  }

  // Calculate the duration
  const duration = moment.duration(outTime.diff(inTime));
  const diffHours = Math.floor(duration.asHours());
  const diffMinutes = duration.minutes();

  // return `${diffHours} hrs ${diffMinutes} min`;
  return punchOut
    ? `${diffHours > 0 ? ` ${diffHours} hrs` : ""} ${
        diffMinutes > 0 ? `${diffMinutes} mins` : ""
      } `
    : `${
        diffHours > 0 ? ` ${diffHours} hrs` : ""
      } ${diffMinutes} mins (Running)`;
};

const EmployeeList = ({ employeList, onEdit, date }) => {
  // console.log(employeList, "d");

  const sortedEmployeeList = [...employeList].sort((a, b) =>
    a?.employee?.name.localeCompare(b?.employee?.name)
  );

  const renderAttendanceShifts = (attendance) => {
    const printedShifts = new Set(); // Use Set to track unique shifts
    return attendance.map((entry, idx) => {
      if (!printedShifts.has(entry?.shift?.name?.trim())) {
        printedShifts.add(entry?.shift?.name?.trim()); // Mark shift as printed
        return (
          <div key={idx}>
            <span
              className="me-2"
              style={{
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
              }}
            >
              {entry?.shift?.name?.trim()}
            </span>
          </div>
        );
      } else {
        return (
          <div key={idx}>
            <span
              className="me-2"
              style={{
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
              }}
            >
              _ _ _
            </span>
          </div>
        );
      }
    });
  };

  return (
    <div className="mt-3 table-responsive">
      <table className="table table-md table-hover  table-responsive ">
        {/* Table Header */}
        <thead>
          <tr style={{ border: 0, borderColor: "transparent" }}>
            <th
              scope="col"
              style={{
                ...stylebg,
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
              }}
            ></th>
            <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 170 }}
            >
              Name
            </th>

            {/* <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 130 }}
            >
              Mobile Number
            </th> */}
            {/* <th scope="col" className="fw-semibold" style={stylebg}>
              Job Title
            </th> */}
            <th scope="col" className="fw-semibold" style={stylebg}>
              Department
            </th>

            <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 200 }}
            >
              {/* Shift */}
              {/* Total Work Hrs */}
              Allocated Shift
            </th>
            {/* <th scope="col" className="fw-semibold" style={stylebg}>
              Status
            </th> */}
            {date?.endDate && (
              <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
                Date
              </th>
            )}
            <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
              Working Shift
            </th>
            <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 90 }}
            >
              Punch-In
            </th>
            <th
              scope="col"
              className="fw-semibold"
              style={{ ...stylebg, width: 90 }}
            >
              Punch-Out
            </th>

            <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
              Completed Hrs
            </th>
            <th scope="col" className="fw-semibold" style={{ ...stylebg }}>
              Status
            </th>
            <th
              scope="col"
              className="fw-semibold"
              style={{
                ...stylebg,
                borderTopRightRadius: 12,
                borderBottomRightRadius: 12,
              }}
            >
              {/* Action */}
            </th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody style={{ verticalAlign: "middle" }}>
          {sortedEmployeeList.map((item, index) => (
            <tr
              key={item?.employee.id}
              style={{
                fontSize: 14,
              }}
              className="fw-medium"
            >
              <th scope="row">{index + 1}</th>
              <td>
                <div className="d-flex align-items-center ">
                  {/* {item?.employee?.imageUrl && (
                    <img
                      src={item?.employee?.imageUrl}
                      alt={item?.employee?.name}
                      className="rounded-circle me-2"
                      style={{ width: "40px", height: "40px" }}
                    />
                  )} */}
                  {item?.employee?.name}
                </div>
              </td>

              {/* <td>
                {item?.employee?.mobileNo ? item?.employee?.mobileNo : "_ _ _"}
              </td> */}
              {/* <td>
                {item?.employee?.designation
                  ? item?.employee?.designation
                  : "_ _ _"}
              </td> */}
              <td>
                {item?.employee?.department?.name
                  ? item?.employee?.department?.name
                  : "_ _ _"}
              </td>
              {/* <td>
                {moment(item?.employee?.joiningDate).format("DD-MM-YYYY")}
              </td> */}

              <td style={{ whiteSpace: "nowrap" }}>
                {item?.employee?.shift?.name ? (
                  <>
                    {/* {item?.employee?.shift?.name} */}
                    {/* {item?.employee?.shift?.startTime &&
                      formatTime(item?.employee?.shift?.startTime)}{" "}
                    -{" "}
                    {item?.employee?.shift?.endTime &&
                      formatTime(item?.employee?.shift?.endTime)}
                    , {item?.employee?.shift?.totalHours} hrs */}
                    {item?.employee?.shift?.name}
                  </>
                ) : (
                  "_ _ _"
                )}
              </td>
              {date?.endDate && (
                <td>
                  <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
                    {item?.attendance?.length > 0 &&
                    !item?.attendance?.includes(null) ? (
                      item?.attendance.map((entry, idx) => (
                        <div key={idx}>
                          {entry?.date
                            ? moment(entry?.date).format("DD-MMM-YY")
                            : "_ _ _"}
                        </div>
                      ))
                    ) : (
                      <div className="fw-medium">_ _ _</div>
                    )}
                  </div>
                </td>
              )}
              <td>
                <div className="fw-medium">
                  {item?.attendance?.length > 0 &&
                  !item?.attendance?.includes(null) ? (
                    renderAttendanceShifts(item?.attendance)
                  ) : (
                    <div className="fw-medium">_ _ _</div>
                  )}
                </div>
              </td>
              <td>
                <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
                  {item?.attendance?.length > 0 &&
                  !item?.attendance?.includes(null) ? (
                    item?.attendance.map((entry, idx) => (
                      <div key={idx}>
                        {entry?.checkInTime
                          ? formatTime(entry?.checkInTime)
                          : "_ _ _"}
                      </div>
                    ))
                  ) : (
                    <div className="fw-medium">_ _ _</div>
                  )}
                </div>
              </td>

              <td>
                <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
                  {item?.attendance?.length > 0 &&
                  !item?.attendance?.includes(null) ? (
                    item?.attendance.map((entry, idx) => (
                      <div key={idx}>
                        {entry?.checkOutTime
                          ? formatTime(entry?.checkOutTime)
                          : "_ _ _"}
                      </div>
                    ))
                  ) : (
                    <div className="fw-medium">_ _ _</div>
                  )}
                </div>
              </td>

              <td>
                <div className="fw-medium" style={{ whiteSpace: "nowrap" }}>
                  {item?.attendance?.length > 0 &&
                  !item?.attendance?.includes(null) ? (
                    item?.attendance.map((entry, idx) => (
                      <div key={idx}>
                        {calculateWorkHours(
                          entry?.checkInTime,
                          entry?.checkOutTime
                        )}

                        {entry?.overtimeHours !== 0 && (
                          <span className="ms-1 text-danger">
                            {idx === item?.attendance?.length - 1 &&
                              `OT (${entry?.overtimeHours} hrs)`}
                          </span>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="fw-medium">_ _ _</div>
                  )}
                </div>
              </td>

              <td>
                {!item?.attendance?.includes(null) ? (
                  item?.attendance?.map((entry, idx) => (
                    <React.Fragment key={entry?.id}>
                      {idx === 0 && (
                        <div className="d-flex align-items-center ">
                          <div
                            className={`rounded-circle border border-2 ${
                              entry?.checkInTime !== "" ||
                              entry?.checkInTime !== null
                                ? "border-success"
                                : "border-danger"
                            }`}
                            style={{
                              width: 8,
                              height: 8,
                              marginRight: 5,
                              // borderRadius: 100,
                            }}
                          />
                          <span
                            className="fw-medium"
                            style={{
                              fontSize: 14,
                              marginBottom: 1,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {entry?.checkInTime !== "" ||
                            entry?.checkInTime !== null
                              ? "P"
                              : "Abs"}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <div className="d-flex align-items-center ">
                    <div
                      className={`rounded-circle border border-2 ${"border-danger"}`}
                      style={{
                        width: 8,
                        height: 8,
                        marginRight: 5,
                        // borderRadius: 100,
                      }}
                    />
                    <span
                      className="fw-medium"
                      style={{
                        fontSize: 14,
                        marginBottom: 1,
                        whiteSpace: "nowrap",
                      }}
                    >
                      A
                    </span>
                  </div>
                )}
              </td>

              <td>
                <EditButton onEdit={() => onEdit(item)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeList;
