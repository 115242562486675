// import React, { useState, useEffect, useRef } from "react";
// import "./CustomDropdown.css";
// import "../ui/CSS/focusinput.css";

// const CustomDropdown = ({
//   inputRef = null,
//   options,
//   onSelect,
//   handleInputChange,
//   searchTerm,
//   placeHolder = "Diagnosis",
//   value,
//   rowIndex,
//   onFocus,
//   onBlur,
//   loading,
//   onAddNewItem,
// }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [highlightedIndex, setHighlightedIndex] = useState(0);
//   const [showCreateOption, setShowCreateOption] = useState(false);

//   // console.log(options, "options")
//   // Filter options based on search term
//   const filteredOptions = options?.filter((option) =>
//     option.name?.toUpperCase().startsWith(searchTerm)
//   );
//   // console.log(filteredOptions, "options filter")
//   // Handle when an option is clicked
//   const handleOptionClick = (option) => {
//     setIsOpen(false);
//     onSelect(option, rowIndex); // Pass the rowIndex to identify which row was selected
//   };

//   // Handle input focus out (close the dropdown)
//   const handleInputFocusOut = () => {

//     // setTimeout(() => {
//     if (onBlur) onBlur();
//     setIsOpen(false)
//     // }, 100); // Delay to allow selection before closing
//   };

//   // Handle input focus (open the dropdown and trigger search)
//   const handleInputFocus = (e) => {
//     if (onFocus) onFocus();
//     handleInputChange(e.target.value, rowIndex)
//     setIsOpen(true); // Open dropdown on input focus
//   };

//   // Handle key navigation and selection
//   const handleKeyDown = (e) => {
//     if (e.key === "ArrowDown") {
//       setHighlightedIndex((prevIndex) =>
//         prevIndex === filteredOptions.length - 1 ? 0 : prevIndex + 1
//       );
//     } else if (e.key === "ArrowUp") {
//       setHighlightedIndex((prevIndex) =>
//         prevIndex === 0 ? filteredOptions.length - 1 : prevIndex - 1
//       );
//     } else if (e.key === "Enter") {
//       const selected = filteredOptions[highlightedIndex];
//       if (selected) {
//         handleOptionClick(selected);
//       } else {
//         handleAddNewItem();
//       }
//     }
//   };

//   // Handle delayed create option visibility
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       if (searchTerm) {
//         setShowCreateOption(true);
//       }
//     }, 500);

//     return () => {
//       clearTimeout(timer);
//       setShowCreateOption(false);
//     };
//   }, [searchTerm]);

//   useEffect(() => {
//     if (isOpen) {
//       setHighlightedIndex(0); // Reset the highlighted index when dropdown opens
//     }
//   }, [isOpen]);

//   // Handle adding a new item if none is selected
//   const handleAddNewItem = () => {
//     if (searchTerm.trim()) {
//       onAddNewItem(searchTerm.trim(), rowIndex); // Pass rowIndex to handle adding new items
//       setIsOpen(false); // Close dropdown after adding
//     }
//   };

//   return (
//     <div onKeyDown={handleKeyDown} tabIndex="0" className="dropdown">
//       <div className="dropdown-header">
//         <input
//           type="text"
//           ref={inputRef}
//           placeholder={placeHolder}
//           value={value ? value : searchTerm} // Controlled input value based on search term or selected value
//           onChange={(e) => {
//             handleInputChange(e.target.value, rowIndex);
//             setIsOpen(true);
//           }} // Handle input changes with rowIndex
//           onFocus={handleInputFocus} // Start search on focus
//           className="prompt form-control fw-semibold border border-0 custom-place m-0 w-100 rounded-0"
//           onBlur={handleInputFocusOut} // Close dropdown on blur
//           style={{
//             fontSize: 13,
//             height: 21,
//             boxShadow: "none",
//             "&:hover": {
//               border: "none",
//             },
//           }}
//         />
//         <style jsx>{`
//           .custom-place::placeholder {
//             color: lightgrey;
//             opacity: 1;
//           }
//         `}</style>
//       </div>
//       {isOpen && (
//         <div className="dropdown-list">
//           {filteredOptions?.length > 0 ? (
//             filteredOptions?.map((option, index) => (
//               <div
//                 key={option?.id}
//                 className={`dropdown-item ${index === highlightedIndex ? "highlighted" : ""}`}
//                 onMouseDown={() => handleOptionClick(option)} // Handle option click with rowIndex
//               >
//                 {option?.name}
//               </div>
//             ))
//           ) : showCreateOption && filteredOptions.length === 0 && !loading ? (
//             <div className="dropdown-item" onMouseDown={handleAddNewItem}>
//               Create "{searchTerm}"
//             </div>
//           ) : (
//             <div className="dropdown-item">Loading..</div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default CustomDropdown;



import React, { useState, useEffect } from "react";
import "./CustomDropdown.css";
import "../ui/CSS/focusinput.css";

const CustomDropdown = ({
  inputRef = null,
  options = [],
  onSelect,
  handleInputChange,
  searchTerm,
  placeHolder = "Diagnosis",
  value,
  rowIndex,
  onFocus,
  onBlur,
  loading,
  onAddNewItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  // Combine filtered options with "Create new" dynamically

  // const filteredOptions = searchTerm
  //   ? (() => {
  //       const filtered = (options || []).filter((option) =>
  //         option.name?.toUpperCase().startsWith(searchTerm.toUpperCase())
  //       );
  //       const hasExactMatch = filtered.some(
  //         (option) => option.name?.toUpperCase() === searchTerm.toUpperCase()
  //       );
  //       return hasExactMatch
  //         ? filtered
  //         : [...filtered, { id: "add-new", name: `Create "${searchTerm}"` }];
  //     })()
  //   : [...(options || [])];



  const filteredOptions = searchTerm
    ? [
        ...options?.filter((option) =>
          option.name?.toUpperCase().startsWith(searchTerm.toUpperCase())
        ),
        ...(options?.some(
          (option) => option.name?.toUpperCase() === searchTerm.toUpperCase()
        )
          ? [] // Don't add "Create" if an exact match exists
          : [{ id: "add-new", name: `Create "${searchTerm}"` }]),
      ]
    : [...options]; // Show all options when searchTerm is empty




  const handleOptionClick = (option) => {
    if (option.id === "add-new") {
      onAddNewItem(searchTerm.trim(), rowIndex); // Create a new item
    } else {
      onSelect(option, rowIndex); // Select an existing option
    }
    setIsOpen(false); // Close dropdown
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === filteredOptions.length - 1 ? 0 : prevIndex + 1
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === 0 ? filteredOptions.length - 1 : prevIndex - 1
      );
    } else if (e.key === "Enter") {
      const selectedOption = filteredOptions[highlightedIndex];
      if (selectedOption) {
        handleOptionClick(selectedOption); // Trigger selection or creation
      }
    }
  };

  const handleInputFocus = () => {
    if (onFocus) onFocus();
    setIsOpen(true);
  };

  const handleInputBlur = () => {
    if (onBlur) onBlur();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0); // Reset highlight on open
  }, [isOpen]);

  return (
    <div
      onKeyDown={handleKeyDown}
      tabIndex="0"
      className="dropdown"
      style={{ position: "relative" }}
    >
      <div className="dropdown-header">
        <input
          type="text"
          ref={inputRef}
          placeholder={placeHolder}
          value={value || searchTerm}
          onChange={(e) => {
            handleInputChange(e.target.value, rowIndex);
            setIsOpen(true);
          }}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className="prompt form-control fw-semibold border border-0 custom-place m-0 w-100 rounded-0"
          style={{ fontSize: 13, height: 21, boxShadow: "none" }}
        />
        <style jsx>{`
          .custom-place::placeholder {
            color: lightgrey;
            opacity: 1;
          }
        `}</style>
      </div>
      {isOpen && (
        <div
          className="dropdown-list"
          style={{ position: "absolute", zIndex: 1000 }}
        >
          {filteredOptions.map((option, index) => (
            <div
              key={option.id || `option-${index}`}
              className={`dropdown-item ${
                index === highlightedIndex ? "highlighted" : ""
              }`}
              onMouseDown={(e) => {
                e.preventDefault(); // Prevent blur on click
                handleOptionClick(option);
              }}
              style={{
                padding: "5px 10px",
                cursor: "pointer",
                // backgroundColor:
                //   index === highlightedIndex ? "#f0f0f0" : "transparent",
              }}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
