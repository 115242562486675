import {
  faFileExport,
  faDollyBox,
  faMultiply,
  faRefresh,
  faMoneyBill1Wave,
  faClockFour,
  faMoneyBill,
  faHourglass,
  faCashRegister,
  faMoneyBillAlt,
  faShield,
  faClover,
  faSheetPlastic,
} from "@fortawesome/free-solid-svg-icons";
import { Color } from "../report/visitConstant/Color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEffect, useState } from "react";
import moment from "moment";
import apiHelper from "../report/ApiHelpar/apiHelper";
import {
  GENERATE_REPORT,
  GET_ALL_DEPARTMENT,
  GET_ALL_SHIFT,
  GET_DAILY_ATTENDENCE,
} from "../report/visitConstant/ApiConstant";
import EditEmployee from "./component/EditEmployee";
import EmptyEmpView from "./component/EmptyEmpView";
import { formatTime } from "./component/AddNewEmployee";
import { useLocation } from "react-router-dom";
import EmplReportList from "./EmpReportList";
import EmployeeInfoCard from "./component/EmployeeInfoCard";

const EmployeeReport = () => {
  const location = useLocation();

  const defaultDate = location?.state?.date;
  const employeeItem = location?.state?.empItem;
  console.log("..", employeeItem);
  const [date, setDate] = useState({
    startDate:
      defaultDate?.startDate || moment().startOf("month").format("YYYY-MM-DD"),
    endDate: defaultDate?.endDate || moment().format("YYYY-MM-DD"),
  });
  const [totalOT, setTotalOT] = useState(0);
  const [totalCompH, setTalCompH] = useState(0);
  const [totalWShift, setTotalWShift] = useState(0);
  const [uniqueDateLength, setUniqueDateLength] = useState(0);
  const [subLoader, setSubLoader] = useState(false);
  const [refLoading, setRefLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState({
    all: [],
    active: [],
    inactive: [],
    onBoard: [],
  });

  const [activeList, setActiveList] = useState(() => {
    const savedActiveList = sessionStorage.getItem("activeListER");
    return savedActiveList
      ? JSON.parse(savedActiveList)
      : { title: "Total Staff", data: employeeList?.all };
  });

  const [isLoading, setIsLoading] = useState(() => {
    const savedIsLoading = sessionStorage.getItem("isLoadingER");
    return savedIsLoading ? JSON.parse(savedIsLoading) : true;
  });

  const calculateWorkHours = (punchIn, punchOut = null) => {
    const inTime = moment(punchIn, "HH:mm:ss");
    let outTime;

    if (!punchOut) {
      // If punchOut is not provided, use the current time
      outTime = moment();
    } else {
      // If punchOut is provided, parse it
      outTime = moment(punchOut, "HH:mm:ss");
    }

    // If outTime is before inTime, assume it crosses midnight
    if (outTime.isBefore(inTime)) {
      outTime.add(1, "day");
    }

    // Calculate the duration
    const duration = moment.duration(outTime.diff(inTime));
    const diffHours = Math.floor(duration.asHours());
    const diffMinutes = duration.minutes();

    // Return formatted duration
    return `${diffHours > 0 ? ` ${diffHours} hrs` : ""} ${
      diffMinutes > 0 ? `${diffMinutes} mins` : ""
    }`;
  };

  function calculateTotalTime(records) {
    let totalMinutes = 0;

    records.forEach((record) => {
      let checkIn = record.checkInTime;
      let checkOut = record.checkOutTime;

      // Only calculate time for records with both checkInTime and checkOutTime
      if (checkIn && checkOut) {
        let checkInTime = new Date(`1970-01-01T${checkIn}Z`);
        let checkOutTime = new Date(`1970-01-01T${checkOut}Z`);

        // Handle cross-midnight situation
        if (checkOutTime < checkInTime) {
          checkOutTime.setDate(checkOutTime.getDate() + 1);
        }

        let timeDifference = (checkOutTime - checkInTime) / 60000; // Convert to minutes
        totalMinutes += timeDifference;
      }
    });

    let totalHours = Math.floor(totalMinutes / 60);
    let remainingMinutes = totalMinutes % 60;

    return { totalHours, remainingMinutes };
  }

  function countUniqueShiftsByDate(records) {
    let shiftsCount = 0;
    let dateShifts = {};

    // Loop through each record and group by date
    records.forEach((record) => {
      let date = record.date;
      let shiftName = record.shift.name;

      // Initialize the date entry if it doesn't exist
      if (!dateShifts[date]) {
        dateShifts[date] = new Set();
      }

      // Add the shift name to the set for that date
      dateShifts[date].add(shiftName);
    });

    // Count unique shifts for each date
    for (let date in dateShifts) {
      shiftsCount += dateShifts[date].size;
    }

    return shiftsCount;
  }

  const processAttendanceData = async (attendance) => {
    if (attendance?.includes(null)) {
      setTotalWShift(0);
      setTotalOT(0);
      setUniqueDateLength(0);
      setTalCompH(0);
      return;
    }

    const calculateTotalOvertime = (attendance) => {
      return attendance?.reduce((total, entry) => {
        return total + (entry.overtimeHours || 0);
      }, 0);
    };

    let result = calculateTotalTime(attendance);
    let totalUniqueShifts = countUniqueShiftsByDate(attendance);
    const totalOvertime = calculateTotalOvertime(attendance);
    const totalHours = `${
      result.totalHours > 0 ? `${result.totalHours}h` : ""
    } ${result.remainingMinutes}m`;
    const uniqueDatesLength = attendance
      ? new Set(attendance?.map((record) => record.date)).size
      : 0;
    setTotalWShift(totalUniqueShifts);
    setTotalOT(totalOvertime);
    setTalCompH(totalHours);
    setUniqueDateLength(uniqueDatesLength);
  };

  const GetEmployeeList = async () => {
    try {
      const data = {
        startDate: date?.startDate,
        endDate: date?.endDate,
        employeeId: employeeItem?.employee?.id,
      };

      // console.log(data, " kdkkd");
      const res = await apiHelper(GET_DAILY_ATTENDENCE, data);

      const resData = res?.respData?.respMsg;
      console.log(resData, "  responsefor");
      // const no = resData?.find((item) => item.employee?.id == 17);
      // console.log("sksk ", no?.attendance?.length, "ed", no);

      if (Array.isArray(resData)) {
        // setEmployeeList(resData);
        const allResData = {
          all: resData,
          active: resData?.filter(
            (item) =>
              Array.isArray(item.attendance) &&
              item.attendance?.some((a) => a && a?.checkInTime !== null)
          ),
          inactive: resData?.filter(
            (item) =>
              item.attendance?.includes(null) || item.attendance?.length === 0
          ),
          onBoard: [],
        };
        // console.log(r, " allresData");
        setEmployeeList(allResData);
        const defaultList = { title: "Active Staff", data: resData };
        setActiveList(defaultList);

        sessionStorage.setItem("activeListER", JSON.stringify(defaultList));
        processAttendanceData(resData[0]?.attendance);
      } else {
        setEmployeeList([]);
      }
    } catch (e) {
      console.log("Getting error from ManageEmp ", e);
    } finally {
      setIsLoading(false);
      setRefLoading(false);
      setSubLoader(false);
      sessionStorage.setItem("isLoadingER", JSON.stringify(false));
    }
  };

  useEffect(() => {
    GetEmployeeList();
  }, []);

  const jsonToCSV = (json) => {
    const header = [
      "Date",
      "Working Shift",
      "Punch-In",
      "Punch-Out",
      "Completed Hours",
      "Overtime Hours",
    ].join(",");
    // const header = Object.keys(json[0]?.employee).join(",");
    const rows = json.map((row) => {
      const { employee, attendance } = row;

      // Map attendance entries into rows
      const attendanceRows = attendance.map((entry) => {
        const workingShift = entry?.shift?.name;
        const punchIn = entry?.checkInTime
          ? formatTime(entry.checkInTime)
          : "_ _ _";
        const punchOut = entry?.checkOutTime
          ? formatTime(entry.checkOutTime)
          : "_ _ _";
        const dates = entry?.date
          ? moment(entry?.date).format("DD-MMM-YYYY")
          : "_ _ _";
        const completedHours = calculateWorkHours(
          entry?.checkInTime,
          entry?.checkOutTime
        );
        const overtime =
          entry?.overtimeHours !== 0 ? `${entry?.overtimeHours} hrs` : "_ _ _";
        const status = entry?.checkInTime ? "Present" : "Absent";

        return [
          //  employee?.name || "_ _ _",
          //  employee?.mobileNo || "_ _ _",
          //  employee?.department?.name || "_ _ _",
          dates,
          workingShift,
          // employee?.shift?.name || "_ _ _",
          // `${formatTime(employee?.shift?.startTime)} - ${formatTime(
          //   employee?.shift?.endTime
          // )} (${employee?.shift?.totalHours} hrs)`,
          punchIn,
          punchOut,
          completedHours || "_ _ _",
          overtime,
        ].join(",");
      });

      return attendanceRows.join("\n");
    });

    // Calculate number of columns
    const numColumns = header.split(",").length;

    // Create padding for centering text
    const totalPaddingCells = numColumns - 1;
    const leftPaddingCells = Math.floor(totalPaddingCells / 2);
    const rightPaddingCells = totalPaddingCells - leftPaddingCells;

    const centeredText = `${",".repeat(
      leftPaddingCells
    )}"Jivdani Hospital Report Details for "${
      employeeItem?.employee?.name
    } ( From ${moment(date?.startDate).format("DD-MMM-YYYY")} to ${
      date?.endDate ? moment(date?.endDate).format("DD-MMM-YYYY") : "N/A"
    }) ${",".repeat(rightPaddingCells)}`;

    const csvContent = [centeredText, header, ...rows].join("\n");
    return csvContent;
  };

  const downloadCSV = () => {
    const csvContent = jsonToCSV(activeList?.data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // console.log(" jdjd >", activeList?.data);

  return (
    <div style={{ paddingTop: 100 }} className="min-vh-100">
      <div
        className=" d-flex bg-white w-100 px-sm-4 py-sm-4 py-3 py-lg-4 px-3 border-bottom justify-content-between align-items-center"
        style={{ minHeight: 80, gap: 20, flexWrap: "wrap" }}
      >
        <div className="" style={{ flex: 1 }}>
          <div className="d-flex column-gap-2">
            <p
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                margin: 0,
                fontSize: window?.innerWidth < 768 ? "18px" : "22px",
              }}
            >
              {employeeItem?.employee?.name || "Report Details"}
            </p>
            {employeeItem?.employee?.mobileNo && (
              <>
                <span
                  className=""
                  style={{
                    backgroundColor: Color.graydark,
                    height: 30,
                    marginTop: 2,
                    width: 1.5,
                  }}
                ></span>
                <p
                  style={{
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    margin: 0,
                    fontSize: window?.innerWidth < 768 ? "18px" : "22px",
                  }}
                >
                  Mob.{employeeItem?.employee?.mobileNo}
                </p>
              </>
            )}
          </div>
          <span
            title={` 
                 ${
                   employeeItem?.employee?.shift?.startTime
                     ? formatTime(employeeItem?.employee?.shift?.startTime)
                     : ""
                 } - ${
              employeeItem?.employee?.shift?.endTime
                ? formatTime(employeeItem?.employee?.shift?.endTime)
                : ""
            }, ${employeeItem?.employee?.shift?.totalHours || "0"} hrs
                `}
            className="text-muted"
          >
            {employeeItem?.employee?.department?.name
              ? employeeItem?.employee?.department?.name
              : "_ _ _"}{" "}
            -{" "}
            <span className="text-muted">
              {employeeItem?.employee?.shift?.name
                ? employeeItem?.employee?.shift?.name
                : "_ _ _"}
            </span>
          </span>
        </div>
        <div className="d-sm-flex align-items-end " style={{ gap: 20 }}>
          <div className="d-flex column-gap-3 row-gap-2 mb-3 mb-sm-0 ">
            <div className="d-flex align-items-center flex-wrap row-gap-1 ">
              <span className="fw-semibold me-2" style={{ color: Color.grey }}>
                Start
              </span>
              <input
                name="startdate"
                type="date"
                className="form-control fw-medium"
                placeholder="dd/mm/yyyy"
                required
                aria-required="true"
                value={date.startDate}
                onChange={(e) =>
                  setDate((prev) => ({ ...prev, startDate: e?.target?.value }))
                }
                style={{
                  outline: "none",
                  boxShadow: "none",
                  // border: "1px solid gray",
                  borderWidth: 1,
                  width: window?.innerWidth > 850 ? 150 : 148,
                  borderRadius: "12px",
                  borderColor: Color.lightGray,
                }}
              />
            </div>
            <div className="d-flex align-items-center flex-wrap row-gap-1">
              <span className="fw-semibold me-2" style={{ color: Color.grey }}>
                End
              </span>
              <input
                name="enddate"
                type="date"
                className="form-control fw-medium"
                placeholder="Select End Date"
                required
                aria-required="true"
                value={date.endDate}
                onChange={(e) =>
                  setDate((prev) => ({ ...prev, endDate: e?.target?.value }))
                }
                max={moment().format("YYYY-MM-DD")}
                style={{
                  outline: "none",
                  boxShadow: "none",
                  borderWidth: 1,
                  width: window?.innerWidth > 850 ? 150 : 148,
                  borderRadius: "12px",
                  borderColor: Color.lightGray,
                }}
              />
            </div>
          </div>

          <button
            type="button"
            className="btn btn-primary fw-medium align-items-center"
            style={{
              whiteSpace: "nowrap",
              borderWidth: 1,
              width: 76,
              borderRadius: "12px",
              borderColor: Color.primary,
            }}
            onClick={() => {
              setSubLoader(true);
              GetEmployeeList();
            }}
          >
            {subLoader ? (
              <span
                class="spinner-border spinner-border-sm text-white"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <span className="text-center">Submit </span>
            )}
          </button>
        </div>
      </div>
      <div
        className="px-sm-4 px-3"
        style={{
          maxHeight: "90vh",
          overflowY: "scroll",
          paddingBottom: 40,
        }}
      >
        <div
          className="d-flex justify-content-between my-sm-4 my-3 "
          style={{
            columnGap: "20px",
            // minHeight: 224,
            minHeight: 100,
          }}
        >
          <div
            className="d-flex "
            style={{
              gap: "20px",
              flex: 1.5,
              flexWrap: "wrap",
            }}
          >
            <EmployeeInfoCard
              icon={faMoneyBill1Wave}
              title="Net Salary"
              value={employeeItem?.employee?.netSalary}
              onPress={() => {}}
            />
            <EmployeeInfoCard
              icon={faSheetPlastic}
              title={
                window?.innerWidth < 768
                  ? "Total Wo. Hrs"
                  : "Total Working Shift"
              }
              value={totalWShift}
              onPress={() => {}}
            />
            <EmployeeInfoCard
              icon={faClockFour}
              title={
                window?.innerWidth < 768
                  ? "Total Co. Hrs"
                  : "Total Completed Hours"
              }
              value={totalCompH}
              onPress={() => {}}
            />

            <EmployeeInfoCard
              icon={faHourglass}
              title={
                window?.innerWidth < 768
                  ? "Total OT hrs"
                  : "Total Overtime Hours"
              }
              value={totalOT}
              onPress={() => {}}
            />
          </div>
          {/* <div className="" style={{ flex: 1, height: "100%" }}>
            <PendingApprovalCard />
          </div> */}
        </div>
        <div
          className="bg-white p-3"
          style={{
            borderRadius: "20px",
            border: "1px solid #E5E5E5",
            boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
            marginBottom: "20px",
          }}
        >
          <div className="d-flex justify-content-between g-2 my-2  align-items-center  py-1">
            <p
              className="fw-semibold text-center"
              style={{
                fontFamily: "revert-layer",
                color: "black",
                margin: 0,
              }}
            >
              All dates ({uniqueDateLength})
            </p>
            <div className="d-flex" style={{ gap: 20 }}>
              <button
                type="button"
                className="d-flex align-items-center btn text-black  fw-medium"
                style={{
                  whiteSpace: "nowrap",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E5E5E5",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  borderColor: Color.lightGray,
                  fontSize: 14,
                  height: 36,
                }}
                onClick={downloadCSV}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  color={"black"}
                  className="me-sm-2"
                  style={{ fontSize: 14 }}
                />
                <span className="d-none d-sm-block">Export To CSV</span>
              </button>
              <button
                type="button"
                className="d-flex align-items-center btn text-white  fw-medium"
                style={{
                  whiteSpace: "nowrap",
                  borderRadius: "12px",
                  backgroundColor: "#f15757",
                  border: "1px solid #E5E5E5",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  borderColor: Color.lightGray,
                  fontSize: 14,
                  height: 36,
                }}
                onClick={() => {
                  setRefLoading(true);
                  GetEmployeeList();
                }}
              >
                <FontAwesomeIcon
                  icon={faRefresh}
                  color={"white"}
                  className={`me-sm-2 ${refLoading && "fa-spin"}`}
                  style={{ fontSize: 14 }}
                />
                <span className="d-none d-sm-block">Refresh</span>
              </button>
              {/* <AddNewEmployee /> */}
            </div>
          </div>

          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 150 }}
            >
              <span
                class="spinner-border spinner-border-sm text-primary mb-5"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          ) : !activeList.data[0]?.attendance?.includes(null) ? (
            <div
              style={{
                overflowY: "scroll",
                maxHeight: "90vh",
              }}
            >
              <EmplReportList employeList={activeList.data} date={date} />
            </div>
          ) : (
            <div className="mb-5">
              <EmptyEmpView title={`No record found !`} icon={faDollyBox} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeReport;
