module.exports = Object.freeze({
  TOP_BANNER: "top_banner_01",
  TOP_PRODUCT: "top_product_01",
  CARD_VIEW: "card_view_01",
  full_width_card_view: "full_width_card_view",
  you_tube_view: "you_tube_view",
  you_tube_view01: "you_tube_view_01",
  CARD_VIEW_WITH_TEXT: "card_view_with_text",
  Article_view: "Article_view",
  article_view_01: "article_view_01",
  CUSTOMER_REVIEW: "CustomerReview",
  BRAND_HEADING_VIEW: "brand_heading_view",
  ABOUT_US_VIEW: "about_us_view",
  CUSTOMER_ID: "customerId",
  DOCTOR_ID: "doctorId",
  SESSION_ID: "sessionId",
  ACCESS_TOKEN: "access_token",
  PASSWORD: "password",
  USER_ROLE: "user_role",
  USER_ROLE_CHANGES_BUTTON: "user_role_button",
  OPD_COUNT: "opd_count",

  ROLE: "ROLE_ADMIN",
  ROLE_LAB: "ROLE_LAB",
  SUPER_ADNIM: "SUPER_ADMIN",
  CART_COUNT: "cart_count",
  BLOGGER: "ROLE_BLOGGER",
  USER_INFO: "user_info",
  USER_LOGIN: "user",
  ROLEDOCTOR: "ROLE_DOCTOR",
  FULL_BODY_CHEAKUP: "FullBodyCheakup",
  // ADD_CART: "add_cart",

  ERROR_MSG: "Something went wrong try again later !",
  NO_HISTORY_FOUND: "No history found for this token",
  CALLING_TAG_OPEN_NATIVE: "J001",
  CALLING_TAG_OPEN_WEB: "J002",
  CALLLINK_LINK_ADD_TO_CART: "add_cart",
  CALLLINK_LINK_BOOK_APPOINTMENT: "book_appointment",

  // BASE_URL: "https://jivdanihospital.com/jivdani-v1/",

  BASE_URL: "https://jivdanihospital.com/jivdani-v1/",
   // BASE_URL: "http://68.178.169.188:8888/jivdani-dev/",

  // BASE_URL: "http://localhost:8443/jivdani-v1/",
  // BASE_URL: "http://localhost:8888/jivdani-dev/",

  GOOGLE_DRIVE_URL: "https://drive.google.com/uc?id=",
  UPDATE_CUSTOMER: "updateCustomer",
  IMG_BASE_URL:
    "https://storage.googleapis.com/jivdanihospital.com/jivdani_img/",
  GET_CART_COUNT: "getCartCount",
  ADD_TO_CART: "addToCart",
  VISIT_ERROR_MSG: "Data failed to save please try again later !",
  VISIT_ERROR_MSG_Catch: "please check your internet connection and try again",
  VISIT_SUCCESS_MSG: "Data has been saved sauccessfully",
  NO_DATA_ENTERED: "NO DATA ENTERED",
  ENTER_DATA: "Please fill in at least one field before proceeding.",
  GET_CLIENT_ID: "getPaypalClientId",
  GET_CART_ITEM: "getCartItem",
  GET_OREDER_HISTORY: "getOrderInfoByOrderParam",
  DeleteCategory: "deleteCategory",
  AddCategory: "addCategory",
  ChangeOrderStatus: "changeOrderStatus",
  generateSmsLocalOtp: "generateSmsLocalOtp",
  GetTransKey: "GetTransKey",

  AddCategoryItem: "addCategoryItem",
  DeleteItem: "deleteItem",
  GET_LOGOUT: "login",
  LOGIN: "login",
  SetOrders: "setOrders",
  RemoveFromCart: "removeFromCart",
  AddPassword: "addPassword",
  PRODUCT_LIST: "getCategoryItemLists",
  SEARCH_API: "findSearchByItemTitle",
  getCustomerAddressByUserId: "getCustomerAddressByUserId",
  addCustomerAddress: "addCustomerAddress",
  removeCustomerAddress: "removeCustomerAddress",
  deleteCustomer: "deleteCustomer",
  addCustomer: "addCustomer",

  getCategoryItemByItemId: "getCategoryItemByItemId",
  validateSmsLocalOtp: "validateSmsLocalOtp",
  getOrderInfoByDate: "getOrderInfoByDate",

  DRIVE_LOGO_URL: "1nF-Rig1j9C4aFqVxFD8xQHGNNDUp96RA",
  DEBUG_LOG_ENABLED: false,
  STARTRAZORPAYPAYMENT: "startRazorpayPayment",
  startPhonePePayment: "startPhonePePayment",

  ID_CLIENTS: "clientId",
  ID_REZORPAY: "rezorpay_id",
  SANDBOXRAZORPAYKEYID: "sandboxRazorpayKeyId",
  RAZORPAYKEYID: "razorpayKeyId",
  RAZORPAYKEYIDSECRET: "razorpayKeySecret",
  PAYPALCLIENTID: "paypalClientId",

  ADD_DOCTOR: "addDoctor",
  GetDoctorDatesListsByDoctorIdAndDates:
    "getDoctorDatesListsByDoctorIdAndDates",
  AddDoctorDates: "addDoctorDates",
  AddDoctorDatesList: "addDoctorDatesList",
  AddDoctorSlotList: "addDoctorSlotList",
  GetCategoryLists: "getCategoryLists",
  GENERATEUSERTOKEN: "generateUserToken",
  getUserTokens: "getUserTokens",
  update_User_Token: "updateUserToken",

  DATA_SAVED: "Data saved susccessfully",

  ////DoctorVisitDropdownData
  DOCTOR_VISITPAD: "getVisitPadWithTemplate",
  DOCTOR_VISIT_DATA: "DoctorVisitDropdownData",
  ADDDATAMASTERVISITPADS: "addDataMasterVisitpads",
  GETALLMASTERVISITPADSBUTD: "getAllMasterVisitpadsByID",

  searchMedicationsByName: "searchMedicinesByName",
  SERCHC_MEDICINES_BY_NAMES: "searchMedicinesByNames",

  SEARCH_MEDICINES_BU_ID: "searchMedicinesById",

  addMedicine: "addMedicine",
  SAVEROOTTEMPLATE: "saveRootTemplate",
  addTemplateByCatIdAndTempId: "addTemplateByCatIdAndTempId",

  get_User_Token_By_DateRange: "getUserTokenByDateRange",
  Tokan_Type_RequestCall: 1,
  Tokan_Type_Generate: 0,
  Tokan_Status_In_Queue: 1,
  Tokan_Status_In_Progress: 2,
  Tokan_Status_In_Completed: 3,
  Tokan_Status_In_Pending: 4,
  GOOGLE_ANALYTICS_TRACKING_ID: "G-LR2D6S44V8",
  Request_Call_Msg: "Your call back request has been submitted successfully",

  ///coor

  BACKGROUND:
    "linear-gradient(to bottom, rgba(130, 80, 255, 1), rgba(130, 80, 255, 0))",

  Custome_scroll: `
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track (hidden by default, will only show when scrolling) */
  ::-webkit-scrollbar-track {
    background: transparent; /* Make the track transparent */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1); /* Color of the scrollbar handle */
    border-radius: 4px; /* Roundness of the scrollbar handle */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out; /* Smooth transition */
  }

  /* Handle on hover or while scrolling */
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.4); /* Darker color on hover */
    opacity: 1; /* Make scrollbar visible on hover */
  }

  /* Firefox scrollbar customization */
  scrollbar-width: thin; /* Makes scrollbar thinner in Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* Handle color and track color in Firefox */

  /* Show scrollbar handle on scroll */
  body::-webkit-scrollbar-thumb {
    opacity: 0; /* Hidden by default */
  }

  /* Show scrollbar when scrolling */
  body:active::-webkit-scrollbar-thumb {
    opacity: 1;
  }`,
  remove_number_spin: `
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
});
