import React, { useEffect, useState } from "react";
import axios from "axios";
import apiHelper from "../report/ApiHelpar/apiHelper";
import AdvertisementCards from "./AdvertisementCards";

const AdvertisementForm = () => {
   const [loop, setLoop] = useState(true);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    id:null,
    title: "",
    description: "",
    url: "",
    startDate: "",
    videoDuration: "",
    sounds: false,
    isActive: true,
    // loop: false,
    position: "center", // Default position
    size: "medium", // Default size
    type: "video/mp4", // Default type
    repeatingTime:"",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (
      !formData.title ||
      !formData.description ||
      !formData.url ||
      !formData.startDate
    ) {
      setError("Please fill out all required fields.");
      setLoading(false);
      return;
    }

  

    const data = {
      id:formData.id,
      title: formData.title,
      description: formData.description,
      startDate: formData.startDate,
      isActive: formData.isActive,
      url: formData.url,
      type: formData.type,
      videoDuration: loop ? "0" : formData.videoDuration,
      sounds: formData.sounds,
repeatingTime:formData.repeatingTime,
      // loop: false,
      position: formData.position, // Default position
      size: formData.size, // Default size
    };

       console.log("data", data);
    try {
      const endpoint = "createAdvertisement";
      const response = await apiHelper(endpoint, data);
        console.log("API Response:", response);

      setFormData({
        id:null,
        title: "",
        description: "",
        url: "",
        startDate: "",
        videoDuration: "",
        sounds: false,
        isActive: true,
        position: "center",
        size: "medium",
        type: "video/mp4",
        repeatingTime:"",
      });
      setLoop(true);

      if (response.respData.respMsg.lenght-1){
 alert("Advertisement created successfully!");
      }else{
         alert(response.respData.message);
      }
       
    } catch (err) {
      console.error("Error creating advertisement:", err);
      setError("Failed to create advertisement. Please try again.");
    } finally {
      setLoading(false);
    }
  };

useEffect(()=>{

  GetAllAdvertisementByStatus()
},[])




 const [advertisements, setAdvertisements] = useState([]);
 const [selectedAd, setSelectedAd] = useState(null);

const GetAllAdvertisementByStatus = async () => {



  try {

    const endpoint = "getAllAdvertisementByStatus";
    const response = await apiHelper(endpoint, {});

const data = response.respData.respMsg;
    
     setAdvertisements(data);
    
  } catch (error) {

    console.log(error);
    
  }
  
};


  const handleSelectAd = (ad) => {
    setSelectedAd(ad);
    console.log("Selected Advertisement:", ad);


    setFormData({
      id: ad.id,
      title: ad.title,
      description: ad.description,
      url: ad.url,
      startDate: ad.startDate,
      videoDuration: ad.videoDuration,
      sounds: ad.sounds,
      isActive: ad.isActive,
      // loop: false,
      position: ad.position, // Default position
      size: ad.size, // Default size
      type: ad.type, // Default type
      repeatingTime: ad.repeatingTime,
    });
  };




  return (
    <>
      <div className="container card p-2 mb-3  " style={{ marginTop: 120 }}>
        <h2 className="text-center mb-4">Create Advertisement</h2>
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* Title */}
            <div className="col-md-6 mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
            {/* Description */}
            <div className="col-md-6 mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                rows="1"
                value={formData.description}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>

          <div className="row">
            {/* URL */}

            <div className="col-md-6 mb-3">
              <label htmlFor="url" className="form-label">
                Media URL
              </label>
              <input
                type="url"
                className="form-control"
                id="url"
                name="url"
                value={formData.url}
                onChange={handleChange}
                required
              />
            </div>

            {/* Type */}
            <div className="col-md-6 mb-3">
              <label htmlFor="type" className="form-label">
                Content Type
              </label>
              <select
                className="form-select"
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="video/mp4">Video (MP4)</option>
                <option value="image">Image</option>
                <option value="youtube">YouTube</option>
              </select>
            </div>
          </div>

          <div className="row">
            {/* Video Duration */}
            <div className="col-md-6 mb-3">
              <label htmlFor="videoDuration" className="form-label">
                Video Duration (minutes / MM:SS)
              </label>

              <input
                type="checkbox"
                className="form-check-input mx-2"
                name="isActive"
                checked={loop}
                onChange={() => setLoop(!loop)}
              />
              <label htmlFor="videoDuration" className="form-label">
                loop
              </label>

              <input
          
                className="form-control"
                id="videoDuration"
                name="videoDuration"
                value={formData.videoDuration}
                onChange={handleChange}
                required
                disabled={loop}
              />
            </div>

            {/* Start Date */}
            <div className="col-md-6 mb-3">
              <label htmlFor="startDate" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="row">
            {/* Position */}
            <div className="col-md-6 mb-3">
              <label htmlFor="position" className="form-label">
                Position
              </label>
              <select
                className="form-select"
                id="position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                required
              >
                <option value="screen-split">Screen split</option>
                <option value="top-left">Top Left</option>
                <option value="top-center">Top Center</option>
                <option value="top-right">Top Right</option>
                <option value="middle-left">Middle Left</option>
                <option value="center">Center</option>
                <option value="middle-right">Middle Right</option>
                <option value="bottom-left">Bottom Left</option>
                <option value="bottom-center">Bottom Center</option>
                <option value="bottom-right">Bottom Right</option>
              </select>
            </div>
            {/* Size */}
            <div className="col-md-6 mb-3">
              <label htmlFor="size" className="form-label">
                Size
              </label>
              <select
                className="form-select"
                id="size"
                name="size"
                value={formData.size}
                onChange={handleChange}
                required
              >
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
                <option value="extra-large">Extra Large</option>
              </select>
            </div>
          </div>

          <div className="row">
            {/* Title */}
            <div className="col-md-6 mb-3">
              <label htmlFor="title" className="form-label">
                How many times you need to repeat it (minutes / MM:SS)
              </label>
              <input
               
                className="form-control"
                id="repeatingTime"
                name="repeatingTime"
                value={formData.repeatingTime}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            {/* Sounds */}
            <div className="col-md-6 mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                name="sounds"
                checked={formData.sounds}
                onChange={handleChange}
              />
              <label className="form-check-label ms-2">Enable Muted</label>
            </div>
            {/* Is Active */}
            <div className="col-md-6 mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                name="isActive"
                checked={formData.isActive}
                onChange={handleChange}
              />
              <label className="form-check-label ms-2">Is Active</label>
            </div>
          </div>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Advertisement"}
            </button>
          </div>
        </form>
      </div>

      <AdvertisementCards
        advertisements={advertisements}
        onSelect={handleSelectAd}
      
      />
    </>
  );
};

export default AdvertisementForm;
