import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faBusinessTime,
  faClockRotateLeft,
  faTextHeight,
  faPrescription,
  faFilePrescription,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { Color } from "../../report/visitConstant/Color";
import { faBlogger } from "@fortawesome/free-brands-svg-icons";

const EmployeeMenu = ({ handleSideBar }) => {
  const [activeItemIdx, setActiveItemIdx] = useState(() => {
    const savedActiveIdx = sessionStorage.getItem("savedActiveIdx");
    return savedActiveIdx ? JSON.parse(savedActiveIdx) : 0;
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    let timer;
    if (isExpanded) {
      timer = setTimeout(() => setShowText(true), 300); // Delay text appearance
    } else {
      setShowText(false);
    }
    return () => clearTimeout(timer);
  }, [isExpanded]);

  const handleItemClick = (idx) => {
    setActiveItemIdx(idx);
    handleSideBar(idx);
  };

  const handleMouseEnter = () => setIsExpanded(true);
  const handleMouseLeave = () => setIsExpanded(false);

  const menuItemStyle = (isActive) => ({
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: isActive ? Color.background : "transparent",
    transition: "all 0.3s ease-in-out",
  });

  const iconStyle = (isActive) => ({
    marginRight: isExpanded ? "10px" : "0px",
    color: isActive ? Color.primary : Color.graydark,
  });

  const textStyle = (isActive) => ({
    fontSize: "16px",
    fontWeight: "bold",
    color: isActive ? Color.primary : Color.graydark,
    display: showText ? "block" : "none",
  });

  const textStyle2 = (isActive) => ({
    fontSize: "16px",
    fontWeight: "bold",
    color: isActive ? Color.primary : Color.graydark,
  });

  const sideBarStyle = {
    width: isExpanded ? "240px" : "60px",
    height: "100%",
    transition: "width 0.3s ease-in-out",
    overflow: "hidden",
  };

  const sideBarStyle2 = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  const MenuItem = ({ index, icon, label }) => {
    const isActive = activeItemIdx === index;
    return (
      <div
        style={menuItemStyle(isActive)}
        onClick={() => handleItemClick(index)}
      >
        <div style={{ width: 38 }}>
          <FontAwesomeIcon icon={icon} style={iconStyle(isActive)} />
        </div>
        <span style={textStyle(isActive)}>{label}</span>
      </div>
    );
  };
  const MenuItem2 = ({ index, icon, label }) => {
    const isActive = activeItemIdx === index;
    return (
      <div
        className="me-3"
        style={menuItemStyle(isActive)}
        onClick={() => handleItemClick(index)}
      >
        <div style={{ width: 26 }}>
          <FontAwesomeIcon icon={icon} style={iconStyle(isActive)} />
        </div>
        <span className="text-nowrap" style={textStyle2(isActive)}>
          {label}
        </span>
      </div>
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column d-lg-block d-none  pt-3 px-2 bg-white border-end"
        style={sideBarStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MenuItem index={0} icon={faUsers} label="Manage Staff" />
        <MenuItem index={1} icon={faBusinessTime} label="Attendance" />
        <MenuItem index={2} icon={faClipboard} label="Report" />
        <MenuItem
          index={3}
          icon={faClockRotateLeft}
          label="Shift & Department"
        />
      </div>
      <div
        className="d-flex  d-lg-none d-bloc w-100 py-3 px-4  bg-white border-bottom "
        style={sideBarStyle2}
      >
        <MenuItem2 index={0} icon={faUsers} label="Manage Staff" />
        <MenuItem2 index={1} icon={faBusinessTime} label="Attendance" />
        <MenuItem2 index={2} icon={faClipboard} label="Report" />
        <MenuItem2
          index={3}
          icon={faClockRotateLeft}
          label="Shift & Department"
        />
      </div>
    </>
  );
};

export default EmployeeMenu;
