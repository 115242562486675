import {
  faUserCheck,
  faLayerGroup,
  faUserGroup,
  faSmile,
  faAd,
  faAdd,
  faFileExport,
  faBeerMugEmpty,
  faBoxOpen,
  faBoxTissue,
  faDollyBox,
  faCross,
  faMultiply,
  faEraser,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Color } from "../../report/visitConstant/Color";
import EmployeeInfoCard from "./EmployeeInfoCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmployeeList from "./EmployeeList";
import PendingApprovalCard from "./PendingApprovalCard";
import AddNewEmployee, { formatTime } from "./AddNewEmployee";
import { useEffect, useState } from "react";
import moment from "moment";
import apiHelper from "../../report/ApiHelpar/apiHelper";
import {
  ACTIVE_STATUS_SHIFT,
  ADD_DEPARTMENT,
  ADD_SHIFT,
  GENERATE_REPORT,
  GET_ALL_DEPARTMENT,
  GET_ALL_EMPLOYEE,
  GET_ALL_SHIFT,
  GET_DAILY_ATTENDENCE,
} from "../../report/visitConstant/ApiConstant";
import ManageEmplyeeList, { EditButton } from "./ManageEmplyeeList";
import { all } from "axios";
import EmptyEmpView from "./EmptyEmpView";
import EditEmployee from "./EditEmployee";
import ToastFist from "../../report/Toast/ToastFist";
import { DATA_SAVED, ERROR_MSG } from "../../Constant";
import DepartmentSelector from "./DepartmentSlector";
import { color } from "framer-motion";
import swal from "sweetalert";
const stylebg = {
  backgroundColor: Color.background,
  paddingTop: 18,
  paddingBottom: 18,
  fontSize: 14,
  color: Color.grey,
  whiteSpace: "nowrap",
};

const ShiftView = ({ shiftItems, onEdit, onDelete }) => {
  return (
    <div
      className="me-2"
      style={{
        overflowY: "scroll",
        maxHeight: "70vh",
        paddingBottom: 100,
      }}
    >
      <div className="mt-3 table-responsive">
        <table className="table table-hover table-borderless  ">
          {/* Table Header */}
          <thead>
            <tr>
              <th
                scope="col"
                style={{
                  ...stylebg,
                  borderTopLeftRadius: 12,
                  borderBottomLeftRadius: 12,
                }}
              ></th>
              <th scope="col" className="fw-semibold" style={stylebg}>
                Name
              </th>
              <th scope="col" className="fw-semibold" style={stylebg}>
                Department
              </th>
              <th scope="col" className="fw-semibold" style={stylebg}>
                Punch-In
              </th>
              <th scope="col" className="fw-semibold" style={stylebg}>
                Punch-Out
              </th>
              <th scope="col" className="fw-semibold" style={stylebg}>
                Total Hrs
              </th>
              <th
                scope="col"
                className="fw-semibold"
                style={{
                  ...stylebg,
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                }}
              >
                Action
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody style={{ verticalAlign: "middle" }}>
            {shiftItems.map((item, index) => (
              <tr key={item?.id} className="fw-medium" style={{ fontSize: 14 }}>
                <th scope="row" className="fw-medium">
                  {index + 1}
                </th>
                <td style={{ whiteSpace: "nowrap" }}>
                  <div className="d-flex align-items-center ">
                    {item?.name?.trim()}
                  </div>
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {item?.departmentId?.name}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {item?.startTime ? formatTime(item?.startTime) : "_ _ _"}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {item?.endTime ? formatTime(item?.endTime) : "_ _ _"}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {item?.totalHours ? item?.totalHours + " hrs" : "_ _ _"}
                </td>

                <td>
                  <div className="d-flex">
                    <EditButton onEdit={() => onEdit(item)} />
                    <button
                      type="button"
                      className="btn text-white fw-medium"
                      style={{
                        width: 40,
                        height: 32,
                        borderRadius: "8px",
                        cursor: "pointer",
                        marginLeft: 4,
                        fontSize: 10,
                      }}
                      onClick={() => onDelete(item?.id)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        color={"#f15757"}
                        style={{ fontSize: 18 }}
                      />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const DepartMentView = ({ departmentItems, onEdit }) => {
  return (
    <div
      className="me-2"
      style={{
        overflowY: "scroll",
        maxHeight: "70vh",
        paddingBottom: 100,
      }}
    >
      <div className="mt-3 table-responsive">
        <table className="table table-hover table-borderless  ">
          {/* Table Header */}
          <thead>
            <tr>
              <th
                scope="col"
                style={{
                  ...stylebg,
                  borderTopLeftRadius: 12,
                  borderBottomLeftRadius: 12,
                }}
              ></th>
              <th scope="col" className="fw-semibold" style={stylebg}>
                Name
              </th>

              <th
                scope="col"
                className="fw-semibold"
                style={{
                  ...stylebg,
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                }}
              >
                Action
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody style={{ verticalAlign: "middle" }}>
            {departmentItems.map((item, index) => (
              <tr key={item?.id} className="fw-medium" style={{ fontSize: 14 }}>
                <th scope="row" className="fw-medium">
                  {index + 1}
                </th>
                <td>
                  <div className="d-flex align-items-center ">{item?.name}</div>
                </td>

                <td>
                  <EditButton onEdit={() => onEdit(item)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ShiftDepartment = () => {
  const [activeName, setActiveName] = useState("");
  const [departmentItems, setDepartmentItems] = useState([]);
  const [shiftItems, setShiftItems] = useState([]);
  const [activeShiftItems, setActiveShiftItems] = useState([]);
  const [isEnable, setEnable] = useState(true);
  const [editableObj, setEditableObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [colorStatus, setColorStatus] = useState(3);
  const [showToast, setShowToast] = useState(false);
  const [isAddActive, setIsAddActive] = useState(false);
  const [inputValue, setInputValue] = useState({
    shiftId: null,
    shiftName: "",
    shiftDepID: null,
    shiftDepName: "",
    punchIn: "",
    punchOut: "",
    totalHrs: "",
    depID: null,
    departmentName: "",
  });

  const getAllShiftItem = async () => {
    setIsLoading(true);
    try {
      const shiftRes = await apiHelper(GET_ALL_SHIFT, {});
      const shiftResResponse = shiftRes?.respData?.respMsg;
      console.log(shiftResResponse, "d");
      if (Array.isArray(shiftResResponse)) {
        const sortedShiftList = shiftResResponse?.sort((a, b) =>
          a?.departmentId?.name.localeCompare(b?.departmentId?.name)
        );
        setShiftItems(sortedShiftList);
        setActiveShiftItems(sortedShiftList);
      } else {
        setShiftItems([]);
        setActiveShiftItems([]);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getAllDepartmentItem = async () => {
    try {
      const res = await apiHelper(GET_ALL_DEPARTMENT, {});

      const departmentRes = res?.respData?.respMsg;
      //   console.log(departmentRes, "f");

      if (Array.isArray(departmentRes)) {
        setDepartmentItems(departmentRes);
      } else {
        setDepartmentItems([]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getAllShiftItem();
    getAllDepartmentItem();
  }, []);

  const resetValue = () => {
    setActiveName("");
    setInputValue({
      shiftId: null,
      shiftName: "",
      shiftDepID: null,
      shiftDepName: "",
      punchIn: "",
      punchOut: "",
      totalHrs: "",
      depID: null,
      departmentName: "",
    });
  };

  const setActiveObj = (item) => {
    setActiveName(item?.name);
    if (isEnable) {
      setInputValue({
        shiftId: item?.id,
        shiftName: item?.name,
        shiftDepID: item?.departmentId?.id || null,
        shiftDepName: item?.departmentId?.name || "",
        punchIn: item?.startTime,
        punchOut: item?.endTime,
        totalHrs: item?.totalHours,
        depID: null,
        departmentName: "",
      });
    } else {
      setInputValue({
        shiftId: null,
        shiftName: "",
        shiftDepID: null,
        shiftDepName: "",
        punchIn: "",
        punchOut: "",
        totalHrs: "",
        depID: item?.id,
        departmentName: item?.name,
      });
    }
  };

  console.log(inputValue, " inputvalye ");
  useEffect(() => {
    if (inputValue?.shiftDepID) {
      const filterdDep = shiftItems.filter(
        (item) => item?.departmentId?.id == inputValue?.shiftDepID
      );
      setActiveShiftItems(filterdDep || []);
    } else {
      setActiveShiftItems(shiftItems);
    }
  }, [inputValue?.shiftDepID]);

  const calculateTotalHours = (punchIn, punchOut) => {
    const inTime = moment(punchIn, "HH:mm");
    let outTime = moment(punchOut, "HH:mm");

    // If punchOut is earlier than punchIn, assume it is on the next day
    if (outTime.isBefore(inTime)) {
      outTime.add(1, "day"); // Add 1 day to the outTime
    }

    const duration = moment.duration(outTime.diff(inTime));
    const diffHours = Math.floor(duration.asHours());
    // const diffMinutes = duration.minutes();

    return diffHours;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => {
      const updatedValue = { ...prev, [name]: value };
      // Check if the changed name is 'shiftId'
      if (name === "shiftDepID") {
        if (value) {
          updatedValue.shiftDepID = value?.id || null;
          updatedValue.shiftDepName = value?.name || "";
        }
      }
      if (updatedValue.punchIn && updatedValue.punchOut) {
        updatedValue.totalHrs = calculateTotalHours(
          updatedValue.punchIn,
          updatedValue.punchOut
        );
      }
      return updatedValue;
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    // console.log(inputValue, "entered data ");

    if (isEnable) {
      if (
        !inputValue?.shiftName ||
        !inputValue?.punchIn ||
        !inputValue?.shiftDepID ||
        !inputValue?.punchOut
      ) {
        setShowToast(true);
        setLoading(false);
        setToastMsg("Please enter all data");
        setColorStatus(3);
        return;
      }
    } else {
      if (!inputValue?.departmentName) {
        setShowToast(true);
        setLoading(false);
        setToastMsg("Please enter all data");
        setColorStatus(3);
        return;
      }
    }

    try {
      const shiftData = {
        ...(inputValue?.shiftId && { id: inputValue?.shiftId }),
        departmentId: inputValue?.shiftDepID
          ? {
              id: parseInt(inputValue?.shiftDepID),
              name: inputValue?.shiftDepName,
            }
          : null,
        name: inputValue?.shiftName,
        startTime: inputValue?.punchIn,
        endTime: inputValue?.punchOut,
        totalHours: inputValue?.totalHrs,
      };

      const departmentData = {
        ...(inputValue?.depID && { id: inputValue?.depID }),
        name: inputValue?.departmentName?.toUpperCase(),
      };

      const cleanData = isEnable ? shiftData : departmentData;

      // console.log(cleanData, "passed cleanData ");

      const endPoint = isEnable ? ADD_SHIFT : ADD_DEPARTMENT;

      const response = await apiHelper(endPoint, cleanData);

      //   console.log(response, "response data");
      if (response == false) {
        setShowToast(true);
        setLoading(false);
        setToastMsg(ERROR_MSG);
        setColorStatus(0);
      } else if (response?.respData?.message !== "Data is not saved") {
        setShowToast(true);
        setToastMsg(
          activeName !== "" ? "Data updated successfully" : DATA_SAVED
        );
        setColorStatus(1);
        if (isEnable) {
          getAllShiftItem();
        } else {
          getAllDepartmentItem();
        }
        setLoading(false);
        resetValue();
        setIsAddActive(false);
      } else {
        setShowToast(true);
        setLoading(false);
        setToastMsg(ERROR_MSG);
        setColorStatus(0);
        setIsAddActive(false);
      }
    } catch (e) {
      // console.log("skjjs", e);
      setShowToast(true);
      setLoading(false);
      setToastMsg(ERROR_MSG);
      setColorStatus(0);
      setIsAddActive(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await swal({
        title: "Are you sure?",
        text: "Do you want to deactivate this shift?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: true,
      });

      if (result) {
        await apiHelper(ACTIVE_STATUS_SHIFT, { id });
        swal({
          title: "Deactivated!",
          text: "The shift has been successfully deactivated.",
          icon: "success",
          timer: 2000, // 2 seconds
          buttons: false, // No buttons, auto-closes
        });
        getAllShiftItem();
      }
    } catch (error) {
      console.log("eerr ", error);
      swal({
        title: "Error!",
        text: "Something went wrong. Please try again later.",
        icon: "error",
        timer: 2000, // 2 seconds
        buttons: false, // No buttons, auto-closes
      });
    }
  };

  return (
    <div>
      <div
        className=" d-none d-lg-block d-flex bg-white w-100 p-4 border-bottom justify-content-between align-items-center "
        style={{ minHeight: 80, gap: 20 }}
      >
        <h4
          style={{
            flex: 0.5,
            fontWeight: "bold",
            fontFamily: "sans-serif",
            margin: 0,
          }}
        >
          Manage Staff & Department
        </h4>
      </div>
      <div
        className="px-sm-4 px-3"
        style={{
          maxHeight: "90vh",
          paddingBottom: 40,
          marginTop: 20,
        }}
      >
        <div
          className="col-12 bg-white p-3"
          style={{
            borderRadius: "20px",
            border: "1px solid #E5E5E5",
            boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
            marginBottom: "20px",
          }}
        >
          <div className="d-flex">
            <div className={`col-md-6 col-12 `}>
              <div className="d-flex my-2 pb-1 gap-3 justify-content-between">
                <div className="d-flex align-items-center ">
                  <button
                    type="button"
                    className="btn  fw-medium me-4"
                    style={{
                      whiteSpace: "nowrap",
                      borderRadius: "12px",
                      backgroundColor: isEnable && "#f15757",
                      color: isEnable ? "white" : "#f15757",
                      borderColor: isEnable ? "transparent" : "#f15757",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      setEnable(!isEnable);
                      resetValue();
                      setIsAddActive(false);
                    }}
                  >
                    Shift
                  </button>
                  <button
                    type="button"
                    className="btn fw-medium"
                    style={{
                      whiteSpace: "nowrap",
                      borderRadius: "12px",
                      backgroundColor: !isEnable && "#f15757",
                      color: !isEnable ? "white" : "#f15757",
                      borderColor: !isEnable ? "transparent" : "#f15757",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      setEnable(!isEnable);
                      resetValue();
                      setIsAddActive(false);
                    }}
                  >
                    Department
                  </button>
                </div>
                <button
                  type="button"
                  className="d-block d-md-none d-flex align-items-center align-self-end btn text-white fw-medium "
                  style={{
                    whiteSpace: "nowrap",
                    borderRadius: "12px",

                    borderColor: "red",
                    backgroundColor: "white",
                    fontSize: 14,
                    height: 36,
                  }}
                  onClick={() => {
                    setIsAddActive(!isAddActive);
                  }}
                >
                  <FontAwesomeIcon
                    icon={isAddActive ? faMultiply : faAdd}
                    color={"red"}
                    className="me-sm-2"
                    style={{ fontSize: 14 }}
                  />
                  <span className="d-none d-sm-block " style={{ color: "red" }}>
                    {" "}
                    {isAddActive ? "Close" : "Add New"}
                  </span>
                </button>
              </div>

              {!isAddActive ? (
                <div className={`col-md-12 col-12`}>
                  {" "}
                  {isLoading ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: "90%" }}
                    >
                      <span
                        class="spinner-border spinner-border-sm text-primary mb-5"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                  ) : isEnable ? (
                    activeShiftItems?.length > 0 ? (
                      <ShiftView
                        shiftItems={activeShiftItems}
                        onEdit={(item) => setActiveObj(item)}
                        onDelete={(id) => handleDelete(id)}
                      />
                    ) : (
                      <div className="">
                        <EmptyEmpView
                          title={`There is no shift defined !`}
                          icon={faDollyBox}
                        />
                      </div>
                    )
                  ) : departmentItems?.length > 0 ? (
                    <DepartMentView
                      departmentItems={departmentItems}
                      onEdit={(item) => setActiveObj(item)}
                    />
                  ) : (
                    <div className="mb-4 mt-3">
                      <EmptyEmpView
                        title={`There is no department defined !`}
                        icon={faDollyBox}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className=" col-12 p-1 d-block d-md-none ">
                  <p className="m-0 fw-bold fs-5">
                    {isEnable
                      ? activeName === ""
                        ? "Add New Shift"
                        : `Update ${activeName}`
                      : activeName === ""
                      ? "Add New Department"
                      : `Update ${activeName}`}
                  </p>
                  <form>
                    {isEnable ? (
                      <div class="row row-cols-2">
                        <div className="col-12 col-sm-6 mt-2">
                          <label htmlFor="shiftName" className="col-form-label">
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="shiftName"
                            name="shiftName"
                            placeholder="Enter name"
                            value={inputValue.shiftName}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <DepartmentSelector
                            slectedValue={inputValue?.shiftDepID}
                            handleInputChange={handleInputChange}
                            departmentItems={departmentItems}
                            inputName={"shiftDepID"}
                          />
                        </div>
                        <div class="col mt-2">
                          <label htmlFor="punchIn" className="col-form-label">
                            Punch In
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            id="punchIn"
                            name="punchIn"
                            placeholder="Select punch-in time"
                            style={{ color: "black" }}
                            value={inputValue.punchIn}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div class="col mt-2">
                          <label htmlFor="punchOut" className="col-form-label">
                            Punch Out
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            id="punchOut"
                            name="punchOut"
                            placeholder="Select punch-out time"
                            style={{ color: "black" }}
                            value={inputValue.punchOut}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col mt-2">
                          <label htmlFor="totalHrs" className="col-form-label">
                            Total Hrs
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="totalHrs"
                            name="totalHrs"
                            placeholder=""
                            value={inputValue.totalHrs}
                            onChange={handleInputChange}
                            disabled={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <div class="row row-cols-1">
                        <div className="col mt-2">
                          <label
                            htmlFor="departmentName"
                            className="col-form-label"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="departmentName"
                            name="departmentName"
                            placeholder="Enter name"
                            value={inputValue.departmentName?.toUpperCase()}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    )}
                  </form>

                  <div className="d-flex justify-content-end align-items-end">
                    <button
                      type="button"
                      className="btn btn-primary fw-medium   "
                      // data-bs-dismiss="modal"
                      onClick={async () => handleSubmit()}
                      disabled={loading}
                      style={{
                        borderRadius: "12px",
                        width: 100,
                        marginTop: "34px",
                      }}
                    >
                      {loading ? (
                        <span
                          class="spinner-border spinner-border-sm text-white"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : editableObj && Object.keys(editableObj).length > 0 ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Divider */}
            <div
              className="col-2 d-none d-md-block "
              style={{
                width: 2,
                backgroundColor: Color.lightGray,
                // maxHeight: "70vh",
              }}
            />

            <div className="col-6 ps-4 p-3 d-none d-md-block">
              <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 fw-bold fs-5">
                  {isEnable
                    ? activeName === ""
                      ? "Add New Shift"
                      : `Update ${activeName}`
                    : activeName === ""
                    ? "Add New Department"
                    : `Update ${activeName}`}
                </p>
                <button
                  type="button"
                  className="d-flex align-items-center btn text-white  fw-medium"
                  style={{
                    whiteSpace: "nowrap",
                    borderRadius: "12px",
                    border: "1px solid #E5E5E5",
                    boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                    borderColor: Color.lightGray,
                    fontSize: 14,
                    height: 36,
                  }}
                  onClick={() => {
                    resetValue();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEraser}
                    color={"red"}
                    style={{ fontSize: 14 }}
                  />
                </button>
              </div>
              <form>
                {isEnable ? (
                  <div class="row row-cols-2">
                    <div className="col mt-2">
                      <label htmlFor="shiftName" className="col-form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="shiftName"
                        name="shiftName"
                        placeholder="Enter name"
                        value={inputValue.shiftName}
                        onChange={handleInputChange}
                      />
                    </div>

                    <DepartmentSelector
                      slectedValue={inputValue?.shiftDepID}
                      handleInputChange={handleInputChange}
                      departmentItems={departmentItems}
                      inputName={"shiftDepID"}
                    />

                    <div class="col mt-2">
                      <label htmlFor="punchIn" className="col-form-label">
                        Punch In
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        id="punchIn"
                        name="punchIn"
                        placeholder="Select punch-in time"
                        value={inputValue.punchIn}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div class="col mt-2">
                      <label htmlFor="punchOut" className="col-form-label">
                        Punch Out
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        id="punchOut"
                        name="punchOut"
                        placeholder="Select punch-out time"
                        value={inputValue.punchOut}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div class="col mt-2">
                      <label htmlFor="totalHrs" className="col-form-label">
                        Total Hrs
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="totalHrs"
                        name="totalHrs"
                        placeholder=""
                        value={inputValue.totalHrs}
                        onChange={handleInputChange}
                        disabled={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div class="row row-cols-1">
                    <div className="col mt-2">
                      <label
                        htmlFor="departmentName"
                        className="col-form-label"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="departmentName"
                        name="departmentName"
                        placeholder="Enter name"
                        value={inputValue.departmentName?.toUpperCase()}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}
              </form>

              <div className="d-flex justify-content-end align-items-end">
                <button
                  type="button"
                  className="btn btn-primary fw-medium   "
                  // data-bs-dismiss="modal"
                  onClick={async () => handleSubmit()}
                  disabled={loading}
                  style={{
                    borderRadius: "12px",
                    width: 100,
                    marginTop: "34px",
                  }}
                >
                  {loading ? (
                    <span
                      class="spinner-border spinner-border-sm text-white"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : editableObj && Object.keys(editableObj).length > 0 ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </div>
  );
};

export default ShiftDepartment;
