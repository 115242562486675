// import React, { useState, useEffect } from "react";

// const AdvertisementCards = ({ advertisements, onSelect }) => {
//   return (
//     <div className="container my-4">
//       <div className="row">
//         {advertisements.map((item) => (
//           <div key={item.id} className="col-md-6 col-lg-4 mb-4">
//             <div
//               className="card h-100"
//               style={{ cursor: "pointer" }}
//               onClick={() => onSelect(item)}
//             >
//               <img
//                 src={
//                   item.type === "youtube"
//                     ? `https://img.youtube.com/vi/${new URL(
//                         item.url
//                       ).searchParams.get("v")}/0.jpg`
//                     : "https://via.placeholder.com/150"
//                 }
//                 className="card-img-top"
//                 alt={item.title}
//               />
//               <div className="card-body">
//                 <h5 className="card-title">{item.title}</h5>
//                 <p className="card-text">{item.description}</p>
//                 <p className="card-text">
//                   <small className="text-muted">
//                     {item.startDate
//                       ? `Start Date: ${new Date(
//                           item.startDate
//                         ).toLocaleDateString()}`
//                       : "No Start Date"}
//                   </small>
//                 </p>
//               </div>
//               <div className="card-footer">
//                 <p className="mb-1">
//                   Video Duration: {item.videoDuration || "N/A"}{" "}
//                   {item.videoDuration && item.videoDuration.includes(":")
//                     ? "minutes"
//                     : "seconds"}
//                 </p>
//                 <p className="mb-1">Position: {item.position || "N/A"}</p>
//                 <p className="mb-0">Size: {item.size || "N/A"}</p>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };


// export default AdvertisementCards;



import React from "react";


const AdvertisementTable = ({ advertisements, onSelect }) => {
  return (
    <div className="container my-4">
      <table className="table table-bordered table-striped">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Description</th>
            <th>Start Date</th>
            <th>Video Duration</th>
            <th>Position</th>
            <th>Size</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {advertisements.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.title}</td>
              <td>{item.description}</td>
              <td>
                {item.startDate
                  ? new Date(item.startDate).toLocaleDateString()
                  : "N/A"}
              </td>
              <td>
                {item.videoDuration
                  ? item.videoDuration.includes(":")
                    ? `${item.videoDuration} minutes`
                    : `${item.videoDuration} seconds`
                  : "N/A"}
              </td>
              <td>{item.position || "N/A"}</td>
              <td>{item.size || "N/A"}</td>
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onSelect(item)}
                >
                  Select
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdvertisementTable;

