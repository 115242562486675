import {
  faDeleteLeft,
  faPen,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Color } from "../../report/visitConstant/Color";
import { color } from "framer-motion";
import moment from "moment";
import AddNewEmployee, { formatTime } from "./AddNewEmployee";
import EditEmployee from "./EditEmployee";

// Reusable Edit Button Component
export const EditButton = ({ onEdit }) => {
  return (
    <div
      onClick={onEdit}
      className="d-flex border align-items-center justify-content-center p-1 my-1"
      style={{
        width: 26,
        height: 26,
        borderRadius: "8px",
        gap: 8,
        cursor: "pointer",
      }}
    >
      <FontAwesomeIcon
        icon={faPen}
        className="text-black-50"
        style={{ fontSize: "10px" }}
      />
      {/* <p
        className="text-center fw-semibold text-black-50"
        style={{ margin: 0, fontSize: 14, color: Color.graydark }}
      >
        Edit
      </p> */}
    </div>
  );
};

const stylebg = {
  backgroundColor: Color.background,
  paddingTop: 18,
  paddingBottom: 18,
  fontSize: 14,
  color: Color.grey,
  whiteSpace: "nowrap",
};

const ManageEmplyeeList = ({ employeList, onEdit }) => {
  const sortedEmployeeList = [...employeList].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  return (
    <div className="mt-3 table-responsive">
      <table className="table table-hover  table-responsive  ">
        {/* Table Header */}
        <thead>
          <tr style={{ border: 0, borderColor: "transparent" }}>
            <th
              scope="col"
              style={{
                ...stylebg,
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
              }}
            ></th>
            <th scope="col" className="fw-semibold" style={stylebg}>
              Name
            </th>
            <th scope="col" className="fw-semibold" style={stylebg}>
              Mobile Number
            </th>
            {/* <th scope="col" className="fw-semibold" style={stylebg}>
              Job Title
            </th> */}
            <th scope="col" className="fw-semibold" style={stylebg}>
              Department
            </th>
            <th scope="col" className="fw-semibold" style={stylebg}>
              Joining Date
            </th>

            <th scope="col" className="fw-semibold" style={stylebg}>
              {/* Shift */}
              Allocated Shift
            </th>
            <th scope="col" className="fw-semibold" style={stylebg}>
              {/* Shift */}
              {/* Total Work Hrs */}
              Start Time - End Time
            </th>
            <th scope="col" className="fw-semibold" style={stylebg}>
              Leaving Date
            </th>
            <th scope="col" className="fw-semibold" style={stylebg}>
              Status
            </th>

            <th
              scope="col"
              className="fw-semibold"
              style={{
                ...stylebg,
                borderTopRightRadius: 12,
                borderBottomRightRadius: 12,
              }}
            >
              {/* Action */}
            </th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody style={{ verticalAlign: "middle" }}>
          {sortedEmployeeList.map((item, index) => (
            <tr key={item?.id} className="fw-medium" style={{ fontSize: 14 }}>
              <th scope="row" className="fw-medium">
                {index + 1}
              </th>
              <td>
                <div className="d-flex align-items-center">{item?.name}</div>
              </td>
              <td>{item?.mobileNo ? item?.mobileNo : "_ _ _"}</td>
              {/* <td>{item?.designation ? item?.designation : "_ _ _"}</td> */}
              <td>
                {item?.department?.name ? item?.department?.name : "_ _ _"}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                {item?.joiningDate
                  ? moment(item?.joiningDate).format("DD-MMM-YYYY")
                  : "_ _ _"}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                {item?.shift?.name ? item?.shift?.name : "_ _ _"}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                {item?.shift?.name ? (
                  <>
                    {item?.shift?.startTime &&
                      formatTime(item?.shift?.startTime)}{" "}
                    - {item?.shift?.endTime && formatTime(item?.shift?.endTime)}
                    , {item?.shift?.totalHours} hrs
                  </>
                ) : (
                  "_ _ _"
                )}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                {item?.leavingDate
                  ? moment(item?.leavingDate).format("DD-MMM-YYYY")
                  : "_ _ _"}
              </td>
              <td>
                <div className="d-flex align-items-center ">
                  <div
                    className={`rounded-circle border border-2 ${
                      item?.activeStatus ? "border-success" : "border-danger"
                    }`}
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 5,
                    }}
                  />
                  <span
                    className="fw-medium"
                    style={{
                      fontSize: 14,
                      marginBottom: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item?.activeStatus ? "Acitve" : "Inactive"}
                  </span>
                </div>
              </td>

              <td>
                <div className="d-flex align-items-center">
                  <EditButton onEdit={() => onEdit(item, false)} />
                  <button
                    type="button"
                    className="btn text-white fw-medium"
                    style={{
                      width: 40,
                      height: 32,
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginLeft: 4,
                      fontSize: 10,
                    }}
                    onClick={() => onEdit(item, true)}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      color={"#f15757"}
                      style={{ fontSize: 18 }}
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <EditEmployee /> */}
    </div>
  );
};

export default ManageEmplyeeList;
